import { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/Logo-01.svg';
import { useAddAuthForgotPasswordMutation } from '../services/portal';

const Login = () => {
    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [addAuthForgotPassword, { isLoading }] = useAddAuthForgotPasswordMutation();
    const [confirmed, setConfirmed] = useState(false);

    const onSubmit = (data) => {
        addAuthForgotPassword({
            email: data.email
        })
            .unwrap()
            .then((payload) => {
                setConfirmed(true);
            })
            .catch((error) => console.error('rejected', error));
    };

    function renderPasswordForgotForm() {
        return (
            <Form className="auth-form auth-form-reflow" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="text-center mb-4">
                    <div className="mb-4">
                        <img src={logo} alt="" height="72"></img>
                    </div>
                    <h1 className="h3">Forgot your password</h1>
                </div>

                <p className="mb-4">Please fill in your email below and we will send you an email with instructions on how to reset your password.</p>

                <Form.Group className="form-group mb-4">
                    <Form.Label className="d-block text-left">Email address</Form.Label>
                    <Form.Control type="email" id="inputEmail" className="form-control" size="lg" {...register('email', { required: true })} />
                    {errors?.email && errors.email.type === 'required' && <p className="validate-feedback-invalid">Valid email address is required.</p>}
                </Form.Group>
                <div className="d-block d-md-inline-block mb-2">
                    <Button variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? (
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" style={{ marginRight: 5 }} />
                                Submitting...
                            </>
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </div>
                <div className="d-block d-md-inline-block mb-2">
                    <Button variant="secondary" style={{ marginLeft: 10 }} type="button" onClick={() => navigate('/')}>
                        Cancel
                    </Button>
                </div>
            </Form>
        );
    }

    function renderConfirmationForm() {
        return (
            <Form className="auth-form auth-form-reflow">
                <div className="text-center mb-4">
                    <div className="mb-4">
                        <img src={logo} alt="" height="72"></img>
                    </div>
                    <h1 className="h3">Forgot your password</h1>
                </div>
                <p className="mb-4">Your request has been received. If you are a registered user, then you will receive an email with instructions on how to reset your password shortly.</p>
                <div className="d-block d-md-inline-block mb-2">
                    <Button variant="primary" type="submit" onClick={() => navigate('/')}>
                        OK
                    </Button>
                </div>
            </Form>
        );
    }

    return <main className="auth">{!confirmed ? renderPasswordForgotForm() : renderConfirmationForm()}</main>;
};

export default Login;
