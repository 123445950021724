import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAddTicketMutation } from '../../services/portal';

function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return defaultValue;
    }
    return JSON.parse(stored);
}

const Ticket = () => {
    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [user] = useState(getSessionStorageOrDefault('user', null));
    const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(false);

    const [addTicket] = useAddTicketMutation();

    const handleCancel = () => {
        navigate('/');
    };

    const onSubmit = (formData) => {
        setButtonSubmitDisabled(true);

        addTicket({
            user_id: user.uid,
            subject: formData.subject,
            category: formData.category,
            description: formData.description,
            priority: formData.priority
        })
            .unwrap()
            .then((payload) => {
                navigate('/support/submitted');
            })
            .catch((error) => console.error('rejected', error));
    };

    return (
        <div className="page">
            <div className="page-inner">
                <header className="page-title-bar">
                    <div className="d-flex justify-content-between">
                        <h1 className="page-title"> New Ticket </h1>
                    </div>
                </header>
                <div className="page-section">
                    <div id="labels" className="card">
                        <div className="card-body">
                            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                <h6>Ticket</h6>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="subject">Subject</label>
                                    <div className="input-group">
                                        <label className="input-group-prepend" htmlFor="subject">
                                            <span className="input-group-text"></span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="subject"
                                            placeholder="Ticket subject"
                                            {...register('subject', {
                                                required: true
                                            })}
                                        />
                                    </div>
                                    {errors?.subject && errors.subject.type === 'required' && <p className="validate-feedback-invalid">Ticket subject is required.</p>}
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="category">Category</label>
                                    <select id="category" className="custom-select" {...register('category', { required: false })} defaultValue="None">
                                        <option key="1" value="None">
                                            None
                                        </option>
                                        <option key="2" value="Report access">
                                            Report access
                                        </option>
                                        <option key="3" value="Report not working">
                                            Report not working
                                        </option>
                                        <option key="4" value="Administration">
                                            Administration
                                        </option>
                                        <option key="5" value="Other">
                                            Other
                                        </option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="description">Description</label>
                                    <textarea className="form-control" id="description" rows="6" placeholder="What's the problem?" {...register('description')} />
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="priority">How critical is your request</label>
                                    <select id="priority" className="custom-select" {...register('priority', { required: false })} defaultValue="None">
                                        <option key="1" value="None">
                                            None
                                        </option>
                                        <option key="2" value="Just FYI">
                                            Just FYI
                                        </option>
                                        <option key="3" value="Nothing urgent, can wait">
                                            Nothing urgent, can wait
                                        </option>
                                        <option key="4" value="Administration">
                                            I&apos;m stuck, need assistance
                                        </option>
                                    </select>
                                </div>
                                <hr className="mb-0 mt-0"></hr>
                                <div className="form-actions">
                                    <button className="btn btn-primary" disabled={buttonSubmitDisabled} type="submit">
                                        Save
                                    </button>
                                    <button className="btn btn-secondary" style={{ marginLeft: 10 }} type="button" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ticket;
