import { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ThemeContext from '../../../contexts/ThemeContext';
import { useUpdateAppSettingsMutation } from '../../../services/portal';
import ImageUploader from '../../ImageUploader/ImageUploader';

const Branding = () => {
  let navigate = useNavigate();
  const {
    themeColor,
    colors,
    logoText,
    setLogoText,
    loginPageType,
    setLoginPageType,
    loginAppLogo,
    setLoginAppLogo,
    loginBackgroundImage,
    setLoginBackgroundImage,
    setCSSVariables
  } = useContext(ThemeContext);

  const [color, setColor] = useState(themeColor);
  const [updateAppSettings, { isLoading: isUpdating }] =
    useUpdateAppSettingsMutation();

  useEffect(() => {
    let select = $('#select2-data-array');
    select.select2({
      placeholder: 'Select a theme',
      allowClear: false
    });
    select.on('change', (e) => {
      handleChange(e);
    });
  }, []);

  const handleChange = (e) => {
    setCSSVariables({
      scheme: e.target.value,
      styles: {
        bs_header_color: null
      }
    });
    setColor(e.target.value);
  };

  const handleCancel = () => {
    navigate('/');
  };

  const handleHeaderLogoTextOnChange = (e) => {
    e.preventDefault();
    setLogoText(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    updateAppSettings({
      app_id: window.location.hostname,
      theme: {
        scheme: color,
        styles: {
          bs_primary_color: null,
          bs_header_color: null
        }
      },
      login_page_type: loginPageType,
      logo_text: logoText,
      login_app_logo: loginAppLogo,
      login_background_image: loginBackgroundImage
    })
      .unwrap()
      .then(() => {
        navigate('/');
      })
      .catch((error) => console.error('rejected', error));
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="active"
              linkProps={{ to: '/' }}
              linkAs={Link}>
              <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> Home
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="page-title"> Branding </h1>
          <p className="text-muted">
            Manage site colours and logo image to provide on-brand experience to
            your users.
          </p>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <form autoComplete="off" onSubmit={onSubmit}>
                <h6>Organization Logo</h6>
                <div className="form-group">
                  <div className="row mb-3 mt-3">
                    <div className="col-sm-3  d-flex align-items-center text-muted">
                      Description
                    </div>
                    <div className="col-sm  d-flex align-items-center text-muted"></div>
                    <div className="col-sm d-flex align-items-center text-muted">
                      Used For
                    </div>
                  </div>
                  <hr className="mb-2"></hr>
                  <div className="row mb-3 mt-3">
                    <div className="col-sm-3  d-flex align-items-center">
                      Logo Text
                    </div>
                    <div className="col-sm  d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control"
                        id="headerLogoText"
                        placeholder="Business Intelligence Portal"
                        defaultValue={logoText}
                        onChange={handleHeaderLogoTextOnChange}
                      />
                    </div>
                    <div className="col-sm d-flex flex-column align-self-center">
                      This logo text will appear on the navigation bar.
                      <br />
                      <small className="text-muted">@header-logo-text</small>
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <div className="col-sm-3  d-flex align-items-center">
                      Login App Logo
                    </div>
                    <div className="col-sm  d-flex align-items-center">
                      <ImageUploader
                        image={loginAppLogo}
                        setImage={setLoginAppLogo}
                        message="Upload Logo"
                      />
                    </div>
                    <div className="col-sm d-flex flex-column align-self-center">
                      This logo will appear on the login page as the
                      organization logo.
                      <br />
                      <small className="text-muted">
                        Preferred Image Size: 72px x 72px @ 72 DPI Maximum size
                        of 1MB.
                      </small>
                      {loginAppLogo && loginAppLogo.length > 0 && (
                        <a href="#" onClick={() => setLoginAppLogo(null)}>
                          Remove Logo
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <div className="col-sm-3  d-flex align-items-center">
                      Login Background Image
                    </div>
                    <div className="col-sm  d-flex align-items-center">
                      <ImageUploader
                        image={loginBackgroundImage}
                        setImage={setLoginBackgroundImage}
                        message="Upload Image"
                      />
                    </div>
                    <div className="col-sm d-flex flex-column align-self-center">
                      This image will appear on the login page as the
                      organization cover page image.
                      <br />
                      <small className="text-muted">
                        Preferred Image Size: 1400px x 1080px @ 72 DPI Maximum
                        size of 1MB.
                      </small>
                      {loginBackgroundImage &&
                        loginBackgroundImage.length > 0 && (
                          <a
                            href="#"
                            onClick={() => setLoginBackgroundImage(null)}>
                            Remove Background Image
                          </a>
                        )}
                    </div>
                  </div>
                </div>
                <hr className="mb-4"></hr>
                <h6>Colors</h6>
                <div className="form-group">
                  <div className="row mb-3 mt-3">
                    <div className="col-sm-3  d-flex align-items-center text-muted">
                      Colour Description
                    </div>
                    <div className="col-sm  d-flex align-items-center text-muted"></div>
                    <div className="col-sm d-flex align-items-center text-muted">
                      Used For
                    </div>
                  </div>
                  <hr className="mb-2"></hr>
                  <div className="row mb-3 mt-3">
                    <div className="col-sm-3  d-flex align-items-center">
                      Brand Primary Color
                    </div>
                    <div className="col-sm  d-flex align-items-center">
                      <select
                        id="select2-data-array"
                        className="form-control"
                        data-toggle="select2"
                        data-minimum-results-for-search="Infinity"
                        defaultValue={themeColor}>
                        {colors?.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm d-flex align-items-center">
                      @primary-color
                    </div>
                  </div>
                </div>
                <hr className="mb-4"></hr>
                <h6>Preferences</h6>
                <div className="form-group">
                  <div className="row mb-3 mt-3">
                    <div className="col-sm-3  d-flex align-items-center text-muted">
                      Description
                    </div>
                    <div className="col-sm  d-flex align-items-center text-muted"></div>
                    <div className="col-sm d-flex align-items-center text-muted"></div>
                  </div>
                  <hr className="mb-2"></hr>
                  <div className="row mb-3 mt-3">
                    <div className="col-sm-3  d-flex align-items-center">
                      Login Page Type
                    </div>
                    <div className="col-sm d-flex flex-column align-self-center">
                      <Form.Check
                        bsPrefix="custom-control"
                        label="Basic"
                        name="loginPageType"
                        type="radio"
                        id="loginPageType"
                        defaultChecked={
                          loginPageType === 'basic' ? true : false
                        }
                        onChange={() => setLoginPageType('basic')}
                      />
                      <Form.Check
                        bsPrefix="custom-control"
                        label="Cover"
                        name="loginPageType"
                        type="radio"
                        id="loginPageType"
                        defaultChecked={
                          loginPageType === 'cover' ? true : false
                        }
                        onChange={() => setLoginPageType('cover')}
                      />
                    </div>
                    <div className="col-sm d-flex align-items-center"></div>
                  </div>
                </div>
                <div className="form-actions">
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branding;
