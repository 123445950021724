import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCheckSquare,
  faCoffee,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useAddActivityReportMutation } from '../services/portal';
import ReportService from '../services/report.service';

library.add(faCheckSquare, faCoffee, faCheck, faSpinner);

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

const ReportView = () => {
  let {
    reportType,
    workspaceId,
    workspaceCode,
    reportId,
    reportCode,
    reportName
  } = useParams();
  const [token, setToken] = useState('');
  const [Roles, setRoles] = useState('');
  const [datasets, setDatasets] = useState('');
  const [Username, setUsername] = useState('');
  const [user] = useState(getSessionStorageOrDefault('user', null));

  /* Export file modal window */
  const [show, setShow] = useState(false);

  // PowerBI Report object (to be received via callback)
  const [report, setReport] = useState();
  const [exportCapturedBookmark, setExportCapturedBookmark] = useState(true); //Export current selected values
  React.useEffect(() => {
    console.debug('Export captured bookmark set to', exportCapturedBookmark);
  }, [exportCapturedBookmark]);

  const [exportCurrentPage, setExportCurrentPage] = useState(false); // Only export current active page

  React.useEffect(() => {
    console.debug('Export current page set to', exportCurrentPage);
  }, [exportCurrentPage]);

  const [exportFileformat, setExportFileformat] = useState(); // Export file format

  React.useEffect(() => {
    console.debug('Export file format set to', exportFileformat);
  }, [exportFileformat]);

  const [addActivityReport, { isLoading: isUpdating }] =
    useAddActivityReportMutation();

  useEffect(() => {
    ReportService.getEmbedToken(
      workspaceId,
      workspaceCode,
      reportId,
      reportCode
    ).then(
      (response) => {
        setToken(response.data.accessToken);
        console.log('reportType', reportType);
        const rlsIdentity = response.data.rlsIdentity || {}; // Default to empty object if null

      if (response.data.datasetIds != null) {
        setDatasets(response.data.datasetIds);
      }
      
      // Check if roles and username exist within rlsIdentity before setting state
      if (rlsIdentity.roles != null) {
        setRoles(rlsIdentity.roles);
      }
      if (rlsIdentity.username != null) {
        setUsername(rlsIdentity.username);
      }
        },
      (error) => {
        alert(error.response.data.message);
        setToken(error.response.data.message);
      }
    );
  }, []);

  // This is a workaround to log the activity report for a paginated report
  useEffect(() => {
    if (reportType === 'Paginated Report') {
      logActivityReport('report_view');
    }
  }, []);

  const logActivityReport = (activityType, comments = null) => {
    addActivityReport({
      user_id: user.uid,
      organization_id: user.oid,
      workspace_id: workspaceId,
      report_id: reportId,
      activity_type: activityType,
      comments: comments
    })
      .unwrap()
      .catch((error) => console.error('rejected', error));
  };

  const handleExportModalClose = () => {
    setShow(false);
  };
  const handleExportModalShow = () => setShow(true);

  const handleExportCapturedBookChange = (e) => {
    const value = e.target.value == 'true' ? true : false;
    setExportCapturedBookmark(value);
  };

  const handleExportCurrentPageChange = (e) => {
    const value = e.target.checked;
    setExportCurrentPage(value);
  };

  const getActivePage = async () => {
    const pages = await report.getPages();
    return pages.filter((page) => {
      return page.isActive;
    })[0];
  };

  const getBookmarkActiveState = async () => {
    return report.bookmarksManager.capture({
      allPages: true,
      personalizeVisuals: true
    });
  };

  const handleDownloadFile = async () => {
    let exportFileDescription;
    if (exportFileformat.toLowerCase() === 'pdf') {
      exportFileDescription = 'PDF';
    } else if (exportFileformat.toLowerCase() === 'pptx') {
      exportFileDescription = 'PowerPoint';
    }

    let powerBIReportConfiguration = {};

    if (exportCapturedBookmark === true) {
      if(Roles != '')
        {
          
        let bookmark = await getBookmarkActiveState();
        powerBIReportConfiguration = {
          ...powerBIReportConfiguration,
          defaultBookmark: { state: bookmark.state },
          //targetWorkspaces:[{"id":"cbd27965-d0ef-4d32-acf7-a62478770171"}],
          //datasets:[{"id":datasets}],
          identities:[{"roles":Roles,"username":Username,datasets:datasets}]
        };
        }else{
      let bookmark = await getBookmarkActiveState();
      powerBIReportConfiguration = {
        ...powerBIReportConfiguration,
        defaultBookmark: { state: bookmark.state },
        //targetWorkspaces:[{"id":"cbd27965-d0ef-4d32-acf7-a62478770171"}],
        //datasets:[{"id":datasets}],
        //identities:[{"roles":Roles,"username":Username,datasets:datasets}]
      };
    }
  }

    if (exportCurrentPage === true) {
      if(Roles != '')
        {
        let activePage = await getActivePage();
        powerBIReportConfiguration = {
          ...powerBIReportConfiguration,
          pages: [{ pageName: activePage.name }],
         //targetWorkspaces:[{"id":"cbd27965-d0ef-4d32-acf7-a62478770171"}],
          //datasets:[{"id":datasets}],
          //identities:[{roles:["organization"],username:"pieter@astutetech.co.za",datasets:["958f1d72-c162-47d9-97e2-7d6adda89b39"]}]
          identities:[{"roles":Roles,"username":Username,datasets:datasets}]
        };
      }else{

      let activePage = await getActivePage();
      powerBIReportConfiguration = {
        ...powerBIReportConfiguration,
        pages: [{ pageName: activePage.name }],
       //targetWorkspaces:[{"id":"cbd27965-d0ef-4d32-acf7-a62478770171"}],
        //datasets:[{"id":datasets}],
        //identities:[{roles:["organization"],username:"pieter@astutetech.co.za",datasets:["958f1d72-c162-47d9-97e2-7d6adda89b39"]}]
        //identities:[{"roles":Roles,"username":Username,datasets:datasets}]
      };
    }
    }  

    setShow(false);

    const resolveReportExport = () =>
      new Promise((resolve, reject) => {
        ReportService.getFileToDownload(
          workspaceCode,
          reportCode,
          exportFileformat,
          null,
          powerBIReportConfiguration
        ).then(
          (response) => {
          const today = new Date();
          const date =
            today.getFullYear().toString() +
            '' +
            (today.getMonth() + 101).toString().substring(1) +
            '' +
            (today.getDate() + 100).toString().substring(1);
          const time =
            ('0' + today.getHours()).slice(-2) +
            '' +
            ('0' + today.getMinutes()).slice(-2) +
            '' +
            ('0' + today.getSeconds()).slice(-2);

          const type = response.headers['content-type'];
          const blob = new Blob([response.data], {
            type: type,
            encoding: 'UTF-8'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute(
            'download',
            `${reportName}_${date}_${time}.${exportFileformat}`
          );
          link.click();
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });

    toast.promise(resolveReportExport, {
      pending: {
        render() {
          return (
            <div className="d-flex flex-column">
              <p className="font-size-sm mb-0">
                <strong>Export to {exportFileDescription} in progress</strong>
              </p>
              <p className="font-size-sm">
                Your report is being exported to a {exportFileDescription} file.
                This might take a few minutes.
              </p>
            </div>
          );
        },
        icon: <FontAwesomeIcon icon="spinner" size="lg" spin />
      },
      success: {
        render({ data }) {
          return (
            <div className="d-flex flex-column">
              <p className="font-size-sm mb-0">
                <strong>
                  {exportFileDescription} file is ready for download
                </strong>
              </p>
              <p className="font-size-sm">
                The report was exported to a {exportFileDescription} file and is
                available for you to download.
              </p>
            </div>
          );
        },
        icon: <FontAwesomeIcon icon="check" size="lg" />
      },
      error: {
        render({ data }) {
          return (
            <div className="d-flex flex-column">
              <p className="font-size-sm mb-0">
                <strong>Export failed</strong>
              </p>
              <p className="font-size-sm">
                An error occurred: {data.message}
              </p>
            </div>
          );
        }
      }
    });
    logActivityReport('report_export');
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <ButtonToolbar
            id="actionBar"
            className="justify-content-between"
            aria-label="Report Action Toolbar">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link}>
                <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> My
                Reports
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{reportName}</Breadcrumb.Item>
            </Breadcrumb>
            <ButtonGroup aria-label="Export Report">
              {reportType === 'Report' && (
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i className="fas fa-file-export mr-1"></i> Export{' '}
                    <i className="fa fa-caret-down mr-1"></i>
                  </button>
                  <div className="dropdown-menu">
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => {
                        setExportFileformat('pdf');
                        handleExportModalShow();
                      }}>
                      <i className="fas fa-file-pdf mr-2"></i>PDF
                    </Link>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => {
                        setExportFileformat('pptx');
                        handleExportModalShow();
                      }}>
                      <i className="fas fa-file-powerpoint mr-2"></i>PowerPoint
                    </Link>
                  </div>
                </div>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        </header>
        <div className="page-section">
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: reportCode,
              embedUrl:
                reportType == 'Report'
                  ? 'https://app.powerbi.com/reportEmbed?groupId=' +
                    workspaceCode +
                    '&reportId=' +
                    reportCode +
                    '&actionBarEnabled=true'
                  : 'https://app.powerbi.com/rdlEmbed?reportId=' + reportCode,

              accessToken: token,
              tokenType: models.TokenType.Embed,
              permissions: models.Permissions.ReadWrite,
              viewMode: models.ViewMode.View,
              settings: {
                
                panes: {
                  bookmarks: {
                    visible: false
                  },
                  fields: {
                    expanded: false
                  },
                  filters: {
                    expanded: false,
                    visible: true
                  },
                  pageNavigation: {
                    visible: true,
                    position: models.PageNavigationPosition.Left
                  },
                  selection: {
                    visible: false
                  },
                  syncSlicers: {
                    visible: false
                  },
                  visualizations: {
                    expanded: false
                  }
                },
                bars: {
                  actionBar: {
                    visible: false
                  },
                  statusBar: {
                    visible: false
                  }
                },
                navContentPaneEnabled: true,
                hideErrors: true
              }
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  function () {
                    console.log('Report loaded');
                    logActivityReport('report_view');
                  }
                ],
                [
                  'rendered',
                  function () {
                    console.log('Report rendered');
                  }
                ],
                [
                  'error',
                  function (event) {
                    console.log(event.detail);
                    logActivityReport('report_error', event.detail.message);
                  }
                ],
                [
                  'visualRendered',
                  function (event) {
                    console.log('Visual Rendered');
                  }
                ],
                ['visualClicked', () => console.log('Visual Clicked')],
                ['pageChanged', (event) => console.log('Page Changed')]
              ])
            }
            cssClassName="embedded-report"
            getEmbeddedComponent={(embeddedReport) => {
              setReport(embeddedReport);
            }}
          />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleExportModalClose}
        animation={false}
        centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <h6>Export</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group col-md-6">
            <label htmlFor="export-report-option-select">Export with</label>
            <select
              className="custom-select"
              id="export-report-option-select"
              value={exportCapturedBookmark}
              onChange={(e) => handleExportCapturedBookChange(e)}>
              <option value="true">Current Values</option>
              <option value="false">Default Values</option>
            </select>
          </div>
          <div className="form-group col-md-6">
            <div className="custom-control custom-control-inline custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="export-only-current-page"
                defaultChecked={exportCurrentPage}
                onChange={(e) => handleExportCurrentPageChange(e)}
              />
              <label
                className="custom-control-label"
                htmlFor="export-only-current-page">
                Only export current page
              </label>
            </div>
          </div>
          <hr className="mb-0 mt-0"></hr>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDownloadFile}>
            Export
          </Button>
          <Button variant="secondary" onClick={handleExportModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReportView;
