import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetOrganizationsQuery,
  useGetTenantsQuery,
  useGetWorkspaceQuery,
  useUpdateWorkspaceMutation
} from '../../services/portal';

const EditWorkspace = () => {
  let navigate = useNavigate();
  let { workspaceId } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(false);

  const { data: tenants } = useGetTenantsQuery({ forceRefetch: true });
  const { data: organizations } = useGetOrganizationsQuery({
    forceRefetch: true
  });
  const { data: workspace } = useGetWorkspaceQuery(workspaceId, {
    forceRefetch: true
  });
  const [updateWorkspace, { isLoading: isUpdating }] =
    useUpdateWorkspaceMutation();

  useEffect(() => {
    if (workspace) {
      setValue('tenant', workspace.tenant.tenant_id);
      setValue('organization', workspace.organization.organization_id);
      setValue('name', workspace.workspace_name);
      setValue('shortName', workspace.workspace_shortname);
      setValue('code', workspace.workspace_code);
      setValue('description', workspace.workspace_description);
    }
  }, [workspace]);

  const handleCancel = () => {
    navigate('/workspaces');
  };

  const onSubmit = (formData) => {
    setButtonSubmitDisabled(true);
    updateWorkspace({
      workspace_id: workspaceId,
      workspace_name: formData.name,
      workspace_shortname: formData.shortName,
      workspace_code: formData.code,
      workspace_description: formData.description,
      organization: {
        organization_id: formData.organization
      },
      tenant: {
        tenant_id: formData.tenant
      }
    })
      .unwrap()
      .then((payload) => {
        navigate('/workspaces');
      })
      .catch((error) => console.error('rejected', error));
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="/workspaces">
                  <i className="breadcrumb-icon fa fa-angle-left mr-2"></i>
                  Workspaces
                </a>
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between">
            <h1 className="page-title"> Edit Workspace </h1>
          </div>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h6>Workspace</h6>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="tenant">Tenant</label>
                  <select
                    id="tenant"
                    className="custom-select"
                    {...register('tenant')}>
                    {tenants?.tenants.map((item, key) => {
                      return (
                        <option key={key} value={item.tenant_id}>
                          {item.tenant_name} ({item.tenant_capacity_id})
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="organization">Organization</label>
                  <select
                    id="organization"
                    className="custom-select"
                    {...register('organization', { required: false })}>
                    {organizations?.organizations.map((item, key) => {
                      return (
                        <option key={key} value={item.organization_id}>
                          {item.organization_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Workspace name"
                    {...register('name', { minLength: 3, required: true })}
                  />
                  {errors?.name && errors.name.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Workspace name is required
                    </p>
                  )}
                  {errors?.name && errors.name.type === 'minLength' && (
                    <p className="validate-feedback-invalid">
                      Workspace name must be at least 3 characters.
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="shortName">Short name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="shortName"
                    placeholder="Workspace short name"
                    {...register('shortName', {
                      maxLength: 2,
                      minLength: 2,
                      required: true
                    })}
                  />
                  {errors?.shortName &&
                    errors.shortName.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        Workspace short name is required.
                      </p>
                    )}
                  {errors?.shortName &&
                    errors.shortName.type === 'minLength' && (
                      <p className="validate-feedback-invalid">
                        Workspace short name must be 2 characters.
                      </p>
                    )}
                  {errors?.shortName &&
                    errors.shortName.type === 'maxLength' && (
                      <p className="validate-feedback-invalid">
                        Workspace short name must be 2 characters.
                      </p>
                    )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="code">Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    placeholder="Workspace code"
                    {...register('code', { required: true })}
                  />
                  {errors?.code && errors.code.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Workspace code is required.
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Workspace description"
                    {...register('description', { required: false })}
                  />
                </div>
                <hr className="mb-0 mt-0"></hr>
                <div className="form-actions">
                  <button
                    className="btn btn-primary"
                    disabled={buttonSubmitDisabled}
                    type="submit">
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWorkspace;
