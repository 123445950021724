import { useState } from 'react';
import { Breadcrumb, Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
  useAddUserMutation,
  useGetOrganizationsQuery
} from '../../services/portal';
import DismissibleAlert from '../AlertDismissible/DismissibleAlert';

const CreateUser = () => {
  let navigate = useNavigate();
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');
  const [user, setUser] = useState(getSessionStorageOrDefault('user', null));
  const [selectedRole, setSelectedRole] = useState('user');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { data } = useGetOrganizationsQuery({ forceRefetch: true });
  const [addUser, { isLoading }] = useAddUserMutation();

  function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  }

  const handleCancel = () => {
    navigate('/users');
  };

  const onSubmit = (formData) => {
    addUser({
      organization_id: formData.organization,
      email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone: formData.phone,
      role: formData.userRole,
      company: formData.company,
      department: formData.department
    })
      .unwrap()
      .then((payload) => {
        navigate('/users');
      })
      .catch((error) => {
        setAlertVariant('danger');
        setAlertMessage(error.data.message);
        setAlertVisible(true);
      });
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="active"
              linkProps={{ to: '/users' }}
              linkAs={Link}>
              <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> Users
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="page-title"> New User </h1>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h6>User</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Organization</Form.Label>
                  <select
                    id="organization"
                    className="custom-select"
                    {...register('organization', { required: true })}>
                    {data?.organizations.map((item, key) => {
                      return (
                        <option key={key} value={item.organization_id}>
                          {item.organization_name}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    id="firstName"
                    placeholder="First name"
                    {...register('firstName', { required: true })}
                  />
                  {errors?.firstName &&
                    errors.firstName.type === 'required' && (
                      // eslint-disable-next-line indent
                      <p className="validate-feedback-invalid">
                        First name is required
                      </p>
                    )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    id="lastName"
                    placeholder="Last name"
                    {...register('lastName', { required: true })}
                  />
                  {errors?.lastName && errors.lastName.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Last name is required
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder="Email address"
                    {...register('email', {
                      required: true,
                      pattern:
                        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
                    })}
                  />
                  {errors?.email && (
                    <p className="validate-feedback-invalid">
                      Valid email address is required
                    </p>
                  )}
                  {errors?.email && errors.email.type === 'pattern' && (
                    <p className="validate-feedback-invalid">
                      Valid email address is required
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    id="phone"
                    placeholder="Phone number"
                    {...register('phone', { required: false })}
                  />
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    id="company"
                    placeholder="Company Name"
                    {...register('company', { required: false })}
                  />
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Department</Form.Label>
                  <Form.Control
                    id="department"
                    placeholder="Department"
                    {...register('department', { required: false })}
                  />
                </Form.Group>
                <h6>Role</h6>
                <Form.Group className="form-group mb-0">
                  {user.role === 'admin' && (
                    <div className="visual-picker visual-picker-sm has-peek px-1">
                      <input
                        type="radio"
                        id="vpr01"
                        name="role"
                        value="admin"
                        defaultChecked={selectedRole === 'admin'}
                        onClick={() => setSelectedRole('admin')}
                        {...register('userRole', { required: true })}
                      />
                      <label className="visual-picker-figure" htmlFor="vpr01">
                        <span className="visual-picker-content">
                          <span className="tile tile-sm bg-indigo">
                            <i className="oi oi-person"></i>
                          </span>
                        </span>
                      </label>
                      <span className="visual-picker-peek">Admin</span>
                    </div>
                  )}
                  <div className="visual-picker visual-picker-sm has-peek px-1">
                    <input
                      type="radio"
                      id="vpr02"
                      name="role"
                      value="manager"
                      defaultChecked={selectedRole === 'manager'}
                      onClick={() => setSelectedRole('manager')}
                      {...register('userRole', { required: true })}
                    />
                    <label className="visual-picker-figure" htmlFor="vpr02">
                      <span className="visual-picker-content">
                        <span className="tile tile-sm bg-blue">
                          <i className="oi oi-person"></i>
                        </span>
                      </span>
                    </label>
                    <span className="visual-picker-peek">Manager</span>
                  </div>
                  <div className="visual-picker visual-picker-sm has-peek px-1">
                    <input
                      type="radio"
                      id="vpr03"
                      name="role"
                      value="user"
                      defaultChecked={selectedRole === 'user'}
                      onClick={() => setSelectedRole('user')}
                      {...register('userRole', { required: true })}
                    />
                    <label className="visual-picker-figure" htmlFor="vpr03">
                      <span className="visual-picker-content">
                        <span className="tile tile-sm bg-gray">
                          <i className="oi oi-person"></i>
                        </span>
                      </span>
                    </label>
                    <span className="visual-picker-peek">User</span>
                  </div>
                </Form.Group>
                <hr className="mb-0 mt-0"></hr>
                <div className="form-actions">
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: 5 }}
                        />
                        Submitting...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </Form>
              <DismissibleAlert
                message={alertMessage}
                variant={alertVariant}
                show={alertVisible}
                setShow={setAlertVisible}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
