import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetOrganizationQuery,
  useUpdateOrganizationMutation
} from '../../services/portal';

const EditOrganization = () => {
  let navigate = useNavigate();
  let { organizationId } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(false);

  const { data: organization } = useGetOrganizationQuery(organizationId, {
    forceRefetch: true
  });
  const [updateOrganization, { isLoading: isUpdating }] =
    useUpdateOrganizationMutation();

  useEffect(() => {
    if (organization) {
      setValue('organizationName', organization.organization_name);
      setValue('organizationDescription', organization.comments);
      setValue('primaryName', organization.contact_persons[0].name);
      setValue('primaryEmail', organization.contact_persons[0].email);
      setValue('primaryTelNumber', organization.contact_persons[0].phone);
      setValue('secondaryName', organization.contact_persons[1].name);
      setValue('secondaryEmail', organization.contact_persons[1].email);
      setValue('secondaryTelNumber', organization.contact_persons[1].phone);
    }
  }, [organization]);

  const handleCancel = () => {
    navigate('/organizations');
  };

  const onSubmit = (formData) => {
    setButtonSubmitDisabled(true);
    updateOrganization({
      organization_id: organizationId,
      organization_name: formData.organizationName,
      contact_persons: [
        {
          name: formData.primaryName,
          email: formData.primaryEmail,
          phone: formData.primaryTelNumber
        },
        {
          name: formData.secondaryName,
          email: formData.secondaryEmail,
          phone: formData.secondaryTelNumber
        }
      ],
      comments: formData.organizationDescription
    })
      .unwrap()
      .then((payload) => {
        navigate('/organizations');
      })
      .catch((error) => console.error('rejected', error));
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="/organizations">
                  <i className="breadcrumb-icon fa fa-angle-left mr-2"></i>
                  Organizations
                </a>
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between">
            <h1 className="page-title"> Edit Organization </h1>
          </div>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h6 className="card-title">Organization </h6>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="organizationName">Name</label>
                  <div className="input-group">
                    <label
                      className="input-group-prepend"
                      htmlFor="organizationName">
                      <span className="input-group-text">
                        <span className="far fa-building"></span>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="organizationName"
                      {...register('organizationName', { required: true })}
                    />
                  </div>
                  {errors.organizationName &&
                    errors.organizationName.type === 'required' && (
                      <p className="required">Organization name is required.</p>
                    )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="organizationDescription">Description </label>
                  <textarea
                    className="form-control"
                    id="organizationDescription"
                    rows="3"
                    {...register('organizationDescription')}></textarea>
                </div>
                <h6 className="card-title">Primary contact</h6>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="primaryName">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Full name:
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="primaryName"
                      {...register('primaryName', { required: true })}
                    />
                  </div>
                  {errors.primaryName &&
                    errors.primaryName.type === 'required' && (
                      <p className="required">
                        Primary contact name is required.
                      </p>
                    )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="primaryEmail">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Email:
                      </span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="primaryEmail"
                      {...register('primaryEmail', { required: true })}
                    />
                  </div>
                  {errors.primaryEmail &&
                    errors.primaryEmail.type === 'required' && (
                      <p className="required">
                        Primary contact email is required.
                      </p>
                    )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="primaryTelNumber">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Phone:
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="primaryTelNumber"
                      {...register('primaryTelNumber', { required: true })}
                    />
                  </div>
                  {errors.primaryTelNumber &&
                    errors.primaryTelNumber.type === 'required' && (
                      <p className="required">
                        Primary contact phone number is required.
                      </p>
                    )}
                </div>
                <h6 className="card-title">Secondary contact</h6>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="secondaryName">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Full name:
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="secondaryName"
                      {...register('secondaryName')}
                    />
                  </div>
                </div>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="secondaryEmail">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Email:
                      </span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="secondaryEmail"
                      {...register('secondaryEmail')}
                    />
                  </div>
                </div>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="secondaryTelNumber">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Phone:
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="secondaryTelNumber"
                      {...register('secondaryTelNumber')}
                    />
                  </div>
                </div>
                <hr className="mb-2"></hr>
                <div className="form-actions">
                  <button
                    className="btn btn-primary"
                    disabled={buttonSubmitDisabled}
                    type="submit">
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrganization;
