import { Link, useNavigate } from 'react-router-dom';
import { useGetWorkspacesQuery } from '../../services/portal';

const Workspaces = () => {
  let navigate = useNavigate();

  const {
    data: workspaces,
    error,
    isLoading
  } = useGetWorkspacesQuery({ forceRefetch: true });

  const handleNewWorkspace = () => {
    navigate('/workspaces/new');
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <div className="d-flex justify-content-between">
            <h1 className="page-title">
              <i className="far fa-object-group text-muted mr-2"></i>Workspaces
            </h1>
            <div className="btn-toolbar">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNewWorkspace}>
                + New
              </button>
            </div>
          </div>
        </header>
        <div className="page-section">
          <div className="card card-fluid">
            <div className="table-responsive">
              <table className="table dataTable no-footer table-striped">
                <thead>
                  <tr role="row">
                    <th>Organization</th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {workspaces?.workspaces.map((item, key) => {
                    return (
                      <tr key={key} role="row">
                        <td className="align-middle">
                          {item.organization.organization_name}
                        </td>
                        <td className="align-middle text-truncate">
                          <Link to={`/workspaces/${item.workspace_id}`}>
                            {item.workspace_name}
                          </Link>
                        </td>
                        <td className="align-middle">{item.workspace_code}</td>
                        <td className="align-middle">
                          {item.workspace_description}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workspaces;
