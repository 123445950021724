import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import appLogo from '../assets/images/Logo-01.svg';
import ThemeContext from '../contexts/ThemeContext';
import { authSelector, clearState, loginUser } from '../redux/AuthSlice';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loginAppLogo, loginPageType } = useContext(ThemeContext);
    const { isSuccess, isError, errorMessage } = useSelector(authSelector);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [message, setMessage] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(loginUser({ email: email, password }));
    };

    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        if (isError) {
            setMessage(errorMessage);
            dispatch(clearState());
        }

        if (isSuccess) {
            dispatch(clearState());
            navigate('/');
        }
    }, [isError, isSuccess]);

    return (
        <>
            {loginPageType === 'basic' ? (
                <div className="auth auth-basic">
                    <form className="auth-form" style={{backgroundColor: "transparent"}} onSubmit={handleSubmit} noValidate autoComplete="off">
                        <div className="mb-4">
                            <div className="mb-3">{loginAppLogo && loginAppLogo.length > 0 ? <img src={loginAppLogo} alt="" height="72"></img> : <img src={appLogo} alt="" height="72"></img>}</div>
                            <h1 className="h3">Sign In</h1>
                            <p className="mb-4">Enter your email and password to sign in</p>
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" id="inputEmail" className="form-control form-control-lg" placeholder="Email" autoFocus="" autoComplete="new-email" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group mb-4">
                            <input type="password" id="inputPassword" className="form-control form-control-lg" placeholder="Password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <p style={{ color: 'red' }}> {message} </p>
                            </div>
                        )}
                        <div className="form-group mx-auto p-1 d-flex justify-content-between">
                            <div className="custom-control custom-control-inline custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="remember-me" />
                                <label className="custom-control-label" htmlFor="remember-me">
                                    Remember me
                                </label>
                            </div>
                            <div>
                                <Link className="link" style={{ textDecoration: 'none' }} to="/forgot-password">
                                    Forgot Password
                                </Link>
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <button className="btn btn-lg btn-primary btn-block" type="submit">
                                Sign In
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="auth auth-floated">
                    <form className="auth-form" onSubmit={handleSubmit} noValidate autoComplete="off">
                        <div className="mb-4">
                            <div className="mb-3">{loginAppLogo && loginAppLogo.length > 0 ? <img src={loginAppLogo} alt="" height="72"></img> : <img src={appLogo} alt="" height="72"></img>}</div>
                            <h1 className="h3">Sign In</h1>
                            <p className="mb-4">Enter your email and password to sign in</p>
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" id="inputEmail" className="form-control form-control-lg" placeholder="Email" autoFocus="" autoComplete="new-email" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group mb-4">
                            <input type="password" id="inputPassword" className="form-control form-control-lg" placeholder="Password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <p style={{ color: 'red' }}> {message} </p>
                            </div>
                        )}
                        <div className="form-group mx-auto p-1 d-flex justify-content-between">
                            <div className="custom-control custom-control-inline custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="remember-me" />
                                <label className="custom-control-label" htmlFor="remember-me">
                                    Remember me
                                </label>
                            </div>
                            <div>
                                <Link className="link" style={{ textDecoration: 'none' }} to="/forgot-password">
                                    Forgot Password
                                </Link>
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <button className="btn btn-lg btn-primary btn-block" type="submit">
                                Sign In
                            </button>
                        </div>
                    </form>
                    <div id="auth-announcement" className="auth-announcement"></div>
                </div>
            )}
        </>
    );
};

export default Login;
