import React, { useEffect, useState } from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import ThemeContext from './ThemeContext';
import { useLazyGetAppSettingsQuery } from '../services/portal';

const themes = {
  dark: '/assets/stylesheets/theme-dark.css',
  light: '/assets/stylesheets/theme-light.css'
};

const colors = [
  { name: 'Indigo', value: 'indigo' },
  { name: 'Cool Blue', value: 'cool_blue' },
  { name: 'Light Navy Blue', value: 'light_navy_blue' },
  { name: 'Mid Blue', value: 'mid_blue' },
  { name: 'Medium Turquoise', value: 'medium_turquoise' },
  { name: 'Butterfly Blue', value: 'butterfly_blue' },
  { name: 'Blue Dress', value: 'blue_dress' },
  { name: 'French Blue', value: 'french_blue' },
  { name: 'Dirty Blue', value: 'dirty_blue' },
  { name: 'Ferrari Red', value: 'ferrari_red' },
  { name: 'Artyclick Red', value: 'artyclick_red' },
  { name: 'Tomato Red', value: 'tomato_red' },
  { name: 'Lava', value: 'lava' },
  { name: 'Cardinal', value: 'cardinal' },
  { name: 'Carrot Orange', value: 'carrot_orange' },
  { name: 'Portland Orange', value: 'portland_orange' },
  { name: 'Halloween Orange', value: 'halloween_orange' },
  { name: 'Saffron Mango', value: 'saffron_mango' },
  { name: 'Terra Cotta', value: 'terra_cotta' },
  { name: 'Faded Orange', value: 'faded_orange' },
  { name: 'Salad Green', value: 'salad_green' },
  { name: 'Leaf Green', value: 'leaf_green' },
  { name: 'Lincoln Green', value: 'lincoln_green' },
  { name: 'Frog Green', value: 'frog_green' },
  { name: 'Dark Aqua', value: 'dark_aqua' },
  { name: 'Jade', value: 'jade' },
  { name: 'Fuchsia Blue', value: 'fuchsia_blue' },
  { name: 'Eminence', value: 'eminence' },
  { name: 'Honey Flower', value: 'honey_flower' },
  { name: 'Blueberry', value: 'blueberry' },
  { name: 'Violet Eggplant', value: 'violet_eggplant' },
  { name: 'Butterfly Bush', value: 'butterfly_bush' },
  { name: 'Grey Goose', value: 'grey_goose' },
  { name: 'Grey Chateau', value: 'grey_chateau' },
  { name: 'Battleship Grey', value: 'battleship_grey' },
  { name: 'Oslo Grey', value: 'oslo_grey' },
  { name: 'Boulder', value: 'boulder' },
  { name: 'Davy Grey', value: 'davy_grey' },
  { name: 'Iridium', value: 'iridium' }
];

const ThemeProvider = ({ children }) => {
  const [getAppSettings] = useLazyGetAppSettingsQuery();
  const [isDark, setIsDark] = useState(false);
  const [themeColor, setThemeColor] = useState(null);
  const theme = isDark ? 'dark' : 'light';
  const [loginPageType, setLoginPageType] = useState('cover');
  const [logo, setLogo] = useState('');
  const [logoText, setLogoText] = useState('');
  const [loginAppLogo, setLoginAppLogo] = useState('');
  const [loginBackgroundImage, setLoginBackgroundImage] = useState('');

  useEffect(() => {
    const isDark = localStorage.getItem('isDark') === 'true';
    setIsDark(isDark);
  }, []);

  useEffect(() => {
    getAppSettings(window.location.hostname)
      .unwrap()
      .then((data) => {
        setCSSVariables(data.theme);
        setLogo(data.logo);
        setLogoText(data.logo_text);
        setLoginPageType(data.login_page_type);
        setLoginAppLogo(data.login_app_logo);
        setLoginBackgroundImage(data.login_background_image);
      });
  }, [getAppSettings]);

  // Toggle between light and dark theme
  const toggleTheme = () => {
    localStorage.setItem('isDark', JSON.stringify(!isDark));
    setIsDark(!isDark);
  };

  const setCSSVariables = (t) => {
    const root = document.querySelector(':root');
    root.setAttribute('color-scheme', `${t.scheme}`);

    for (const value in t.styles) {
      if (t.styles[value] !== null && t.styles[value] !== '') {
        let property = value.replace(/_/g, '-');
        document.documentElement.style.setProperty(
          `--${property}`,
          t.styles[value]
        );
      }
    }
    setThemeColor(t.scheme);
  };

  // prettier-ignore
  return (
        themeColor && (
            <ThemeContext.Provider value={{ colors, isDark, theme, themeColor, logo, toggleTheme, setCSSVariables, logoText, setLogoText, loginPageType, setLoginPageType, loginAppLogo, setLoginAppLogo, loginBackgroundImage, setLoginBackgroundImage}}>
              <ThemeSwitcherProvider defaultTheme={theme} themeMap={themes} insertionPoint={document.getElementById('theme')}>
                    {children}
                </ThemeSwitcherProvider>
            </ThemeContext.Provider>
        )
    );
};

export default ThemeProvider;
