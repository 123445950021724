import { useState } from 'react';
import { Button, Card, Modal, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import {
  useGetReportsQuery,
  useUpdateReportStatusMutation
} from '../../services/portal';

const Reports = () => {
  let navigate = useNavigate();

  const { data: reports } = useGetReportsQuery({ forceRefetch: true });
  const [updateReportStatus, { isLoading: isUpdating }] =
    useUpdateReportStatusMutation();

  const [selectedReportId, setSelectedReportId] = useState(0);
  const [selectedReportWorkspace, setSelectedReportWorkspace] = useState('');
  const [selectedReportName, setSelectedReportName] = useState('');
  const [selectedReportStatus, setSelectedReportStatus] = useState(false);

  const [reportModelShow, setReportModalShow] = useState(false);

  const handleReportModalShow = () => {
    setReportModalShow(true);
  };

  const handleReportModalCancel = () => {
    setReportModalShow(false);
  };

  const handleNewReport = () => {
    navigate('/reports/new');
  };

  const handleSaveReportStatus = () => {
    updateReportStatus({
      report_id: selectedReportId,
      status: selectedReportStatus
    })
      .unwrap()
      .then((payload) => {
        console.debug('Report status sucessfully updated');
      })
      .catch((error) => console.error('rejected', error));

    setReportModalShow(false);
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <div className="d-flex justify-content-between">
            <h1 className="page-title">
              <i className="fas fa-chart-pie text-muted mr-2"></i>Reports
            </h1>
            <div className="btn-toolbar">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNewReport}>
                + New
              </button>
            </div>
          </div>
        </header>
        <div className="page-section">
          <Card>
            <Tab.Container
              id="report-tabs"
              defaultActiveKey="active"
              transition={false}>
              <Card.Header>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="active">Active</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="disabled">Disabled</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="active">
                    <div className="table-responsive">
                      <table className="table dataTable no-footer table-striped">
                        <thead>
                          <tr role="row">
                            <th>Organization</th>
                            <th>Workspace</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Description</th>
                            <th style={{ minWidth: '130px', width: '100px' }}>
                              {' '}
                              &nbsp;{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reports?.reports
                            .filter((report) => report.active === true)
                            .map((item, key) => {
                              return (
                                <tr key={key} role="row">
                                  <td className="align-middle">
                                    {item.organization.organization_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.workspace.workspace_name}
                                  </td>
                                  <td className="align-middle text-truncate">
                                    {item.report_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.report_code}
                                  </td>
                                  <td className="align-middle">
                                    {item.report_description}
                                  </td>
                                  <td className="align-middle text-right">
                                    <Link
                                      to={`/notifications/subscriptions/reports/${item.report_type}/${item.report_id}/${item.report_code}/${item.report_name}`}
                                      className="btn btn-sm btn-icon btn-secondary"
                                      style={{ marginRight: 5 }}>
                                      <i className="far fa-bell"></i>{' '}
                                      <span className="sr-only">
                                        Notifications
                                      </span>
                                    </Link>
                                    <Link
                                      to={`/reports/${item.report_id}`}
                                      className="btn btn-sm btn-icon btn-secondary"
                                      style={{ marginRight: 5 }}>
                                      <i className="fa fa-pencil-alt"></i>{' '}
                                      <span className="sr-only">Edit</span>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-icon btn-secondary"
                                      onClick={() => {
                                        setSelectedReportId(item.report_id);
                                        setSelectedReportWorkspace(
                                          item.workspace.workspace_name
                                        );
                                        setSelectedReportName(item.report_name);
                                        setSelectedReportStatus(false);
                                        handleReportModalShow();
                                      }}>
                                      <i className="far fa-trash-alt"></i>{' '}
                                      <span className="sr-only">
                                        Disable report
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="disabled">
                    <div className="table-responsive">
                      <table className="table dataTable no-footer table-striped">
                        <thead>
                          <tr role="row">
                            <th>Organization</th>
                            <th>Workspace</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Description</th>
                            <th style={{ minWidth: '100px', width: '100px' }}>
                              {' '}
                              &nbsp;{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reports?.reports
                            .filter((report) => report.active === false)
                            .map((item, key) => {
                              return (
                                <tr key={key} role="row">
                                  <td className="align-middle">
                                    {item.organization.organization_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.workspace.workspace_name}
                                  </td>
                                  <td className="align-middle text-truncate">
                                    {item.report_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.report_code}
                                  </td>
                                  <td className="align-middle">
                                    {item.report_description}
                                  </td>
                                  <td className="align-middle text-right">
                                    <Link
                                      to={`/reports/${item.report_id}`}
                                      className="btn btn-sm btn-icon btn-secondary"
                                      style={{ marginRight: 5 }}>
                                      <i className="fa fa-pencil-alt"></i>{' '}
                                      <span className="sr-only">Edit</span>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-icon btn-secondary"
                                      onClick={() => {
                                        setSelectedReportId(item.report_id);
                                        setSelectedReportWorkspace(
                                          item.workspace.workspace_name
                                        );
                                        setSelectedReportName(item.report_name);
                                        setSelectedReportStatus(true);
                                        handleReportModalShow();
                                      }}>
                                      <i className="fas fa-trash-restore-alt"></i>{' '}
                                      <span className="sr-only">
                                        Enable report
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </div>
      </div>

      <Modal
        show={reportModelShow}
        onHide={handleReportModalCancel}
        animation={false}
        centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <h6>
              {selectedReportStatus ? 'Activate report' : 'Disable report'}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to{' '}
            {selectedReportStatus ? 'activate' : 'disable'}{' '}
            <strong>{selectedReportName}</strong>?
          </p>
          <hr className="mb-0 mt-0"></hr>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReportModalCancel}>
            Cancel
          </Button>
          <Button variant="warning" onClick={handleSaveReportStatus}>
            {selectedReportStatus ? 'Activate report' : 'Disable report'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Reports;
