import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/images/Logo-01.svg';
import { useAddAuthResetPasswordMutation, useGetAuthResetPasswordQuery } from '../services/portal';

const Login = () => {
    let navigate = useNavigate();
    let { email, token } = useParams();

    const { error: expiredToken } = useGetAuthResetPasswordQuery({ token: token, email: email });
    const [addAuthPasswordReset] = useAddAuthResetPasswordMutation();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm();
    const [confirmed, setConfirmed] = useState(false);

    const onSubmit = (data) => {
        addAuthPasswordReset({
            email: email,
            token: token,
            password1: data.password,
            password2: data.passwordConfirmation
        })
            .unwrap()
            .then((payload) => {
                setConfirmed(true);
            })
            .catch((error) => console.error('rejected', error));
    };

    function renderResetPasswordForm() {
        return (
            <form className="auth-form auth-form-reflow" onSubmit={handleSubmit(onSubmit)}>
                <div className="text-center mb-4">
                    <div className="mb-4">
                        <img src={logo} alt="" height="72"></img>
                    </div>
                    <h1 className="h3">Reset Password</h1>
                </div>

                <p className="mb-4">You can set a new password below.</p>

                <div className="form-group mb-4">
                    <label className="d-block text-left" htmlFor="password">
                        New password
                    </label>
                    <input
                        name="password"
                        type="password"
                        className="form-control form-control-lg"
                        autoComplete="new-password"
                        {...register('password', {
                            required: 'You must specify a password',
                            minLength: {
                                value: 8,
                                message: 'Your password should be of minimum 8 characters, containing both uppercase and lowercase letters and at least 1 number.'
                            },
                            validate: {
                                matchesPasswordRequirements: (value) => {
                                    return (
                                        [/[a-z]/, /[A-Z]/, /[0-9]/, /[a-zA-Z0-9]/].every((pattern) => pattern.test(value)) ||
                                        'Your password should be of minimum 8 characters, containing both uppercase and lowercase letters and at least 1 number.'
                                    );
                                }
                            }
                        })}
                        required
                    />
                    {errors?.password && <p className="validate-feedback-invalid">{errors.password.message}</p>}

                    <label className="d-block text-left mt-4" htmlFor="passwordConfirmation">
                        Confirm new password
                    </label>
                    <input
                        name="passwordConfirmation"
                        type="password"
                        className="form-control form-control-lg"
                        autoComplete="new-password"
                        {...register('passwordConfirmation', {
                            required: 'Please enter the same password again',
                            validate: {
                                matchesPreviousPassword: (value) => {
                                    const { password } = getValues();
                                    return password === value || 'Please enter the same password again';
                                }
                            }
                        })}
                        required
                    />
                    {errors?.passwordConfirmation && <p className="validate-feedback-invalid">{errors.passwordConfirmation.message}</p>}
                </div>
                <div className="d-block d-md-inline-block mb-2">
                    <button className="btn btn-block btn-primary" type="submit">
                        Submit
                    </button>
                </div>
                <div className="d-block d-md-inline-block mb-2">
                    <button className="btn btn-block btn-secondary" style={{ marginLeft: 10 }} type="button" onClick={() => navigate('/')}>
                        Cancel
                    </button>
                </div>
            </form>
        );
    }

    function renderTokenExpiredForm() {
        return (
            <form className="auth-form auth-form-reflow">
                <div className="text-center mb-4">
                    <div className="mb-4">
                        <img src={logo} alt="" height="72"></img>
                    </div>
                    <h1 className="h3">Reset Password</h1>
                </div>
                <p className="mb-4">Sorry your token has expired. Please try password reset again.</p>
                <div className="d-block d-md-inline-block mb-2">
                    <button className="btn btn-block btn-primary" type="submit" onClick={() => navigate('/')}>
                        OK
                    </button>
                </div>
            </form>
        );
    }

    function renderSuccessMessage() {
        return (
            <form className="auth-form auth-form-reflow">
                <div className="text-center mb-4">
                    <div className="mb-4">
                        <img src={logo} alt="" height="72"></img>
                    </div>
                    <h1 className="h3">Reset Password</h1>
                </div>
                <p className="mb-4">Your password has been successfully updated.</p>
                <div className="d-block d-md-inline-block mb-2">
                    <button className="btn btn-block btn-primary" type="submit" onClick={() => navigate('/')}>
                        OK
                    </button>
                </div>
            </form>
        );
    }

    return (
        <main className="auth">
            {expiredToken && !confirmed && renderTokenExpiredForm()}
            {!expiredToken && !confirmed && renderResetPasswordForm()}
            {confirmed && renderSuccessMessage()}
        </main>
    );
};

export default Login;
