import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation
} from 'react-router-dom';

import ForgotPassword from './pages/ForgotPassword';
import Layout from './pages/Layout';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';

TimeAgo.addDefaultLocale(en);

function RequireAuth({ children }) {
  let location = useLocation();
  let token = sessionStorage.getItem('token');

  if (!token) {
    // Redirect user to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const App = () => {
  const { status } = useThemeSwitcher();

  // Avoid theme change flicker
  if (status !== 'loaded') {
    return <div></div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/reset-password/:email/:token"
          element={<ResetPassword />}
        />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
