import React, { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import { DarkModeSwitch } from '../DarkModeSwitch';

export default function ThemeSetter() {
    const { isDark, toggleTheme } = useContext(ThemeContext);

    // prettier-ignore
    return <DarkModeSwitch checked={isDark} onChange={toggleTheme} size={20} />;
}
