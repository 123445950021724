/* eslint-disable indent */
import { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactTags } from 'react-tag-autocomplete';
import * as REPORT from '../../constants/reports';
import {
  useAddReportMutation,
  useGetOrganizationsQuery,
  useGetWorkspacesByOrganizationQuery
} from '../../services/portal';

const CreateReport = () => {
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const [selectedOrganization, setSelectedOrganization] = useState(2);
  const [reportType, setReportType] = useState(REPORT.TYPE.REPORT);
  const [isRowLevelSecurity, setIsRowLevelSecurity] = useState();
  const [isSSASSecurity, setIsSSASSecurity] = useState();

  const { data: organizations } = useGetOrganizationsQuery({
    forceRefetch: true
  });
  const { data: workspaces } = useGetWorkspacesByOrganizationQuery(
    selectedOrganization,
    {
      forceRefetch: true
    }
  );
  const [addReport, { isLoading: isUpdating }] = useAddReportMutation();

  /** Row-Level Security */
  const [selected, setSelected] = useState([]);

  const onAdd = useCallback(
    (newTag) => {
      setSelected([...selected, newTag]);
    },
    [selected]
  );

  const onDelete = useCallback(
    (tagIndex) => {
      setSelected(selected.filter((_, i) => i !== tagIndex));
    },
    [selected]
  );
  // End Row-Level Security

  // Set default values for organizations
  useEffect(() => {
    if (organizations) {
      setValue('organization', organizations.organizations[0].organization_id);
      setSelectedOrganization(organizations.organizations[0].organization_id);
    }
  }, [organizations]);

  // Set default values for tenants
  useEffect(() => {
    if (workspaces && workspaces.workspaces.length > 0) {
      setValue('workspace', workspaces.workspaces[0].workspace_id);
    }
  }, [workspaces]);

  const handleCancel = () => {
    navigate('/reports');
  };

  const handleOrganizationChange = (e) => {
    setSelectedOrganization(e.target.value);
  };

  const onSubmit = (formData) => {
    addReport({
      report_name: formData.name,
      report_shortname: formData.shortName,
      report_code: formData.code,
      report_description: formData.description,
      report_type: reportType,
      report_rls: {
        enabled: isRowLevelSecurity,
        roles:
          reportType === REPORT.TYPE.PAGINATED
            ? []
            : selected.map((select) => select.value)
      },
      report_ssas: {
        enabled: isSSASSecurity,
        username: formData.username
      },
      workspace: {
        workspace_id: formData.workspace
      },
      organization: {
        organization_id: formData.organization
      }
    })
      .unwrap()
      .then((payload) => {
        navigate('/reports');
      })
      .catch((error) => console.error('rejected', error));
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="/reports">
                  <i className="breadcrumb-icon fa fa-angle-left mr-2"></i>
                  Reports
                </a>
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between">
            <h1 className="page-title"> New Report </h1>
          </div>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h6>Report</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Organization</Form.Label>
                  <select
                    id="organization"
                    className="custom-select"
                    {...register('organization')}
                    onChange={handleOrganizationChange}>
                    {organizations?.organizations.map((item, key) => {
                      return (
                        <option key={key} value={item.organization_id}>
                          {item.organization_name}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Workspace</Form.Label>
                  <select
                    id="workspace"
                    className="custom-select"
                    {...register('workspace')}>
                    {workspaces?.workspaces.map((item, key) => {
                      return (
                        <option key={key} value={item.workspace_id}>
                          {item.workspace_name}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    placeholder="Report name"
                    {...register('name', { minLength: 3, required: true })}
                  />
                  {errors?.name && errors.name.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Report name is required.
                    </p>
                  )}
                  {errors?.name && errors.name.type === 'minLength' && (
                    <p className="validate-feedback-invalid">
                      Report name must be at least 3 characters.
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Short name</Form.Label>
                  <Form.Control
                    type="text"
                    id="shortName"
                    placeholder="Report short name"
                    {...register('shortName', {
                      maxLength: 2,
                      minLength: 2,
                      required: true
                    })}
                  />
                  {errors?.shortName &&
                    errors.shortName.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        Report short name is required.
                      </p>
                    )}
                  {errors?.shortName &&
                    errors.shortName.type === 'minLength' && (
                      <p className="validate-feedback-invalid">
                        Report short name must be 2 characters.
                      </p>
                    )}
                  {errors?.shortName &&
                    errors.shortName.type === 'maxLength' && (
                      <p className="validate-feedback-invalid">
                        Report short name must be 2 characters.
                      </p>
                    )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    id="code"
                    placeholder="Report code"
                    {...register('code', { required: true })}
                  />
                  {errors?.code && errors.code.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Report code is required.
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    placeholder="Report description"
                    {...register('description', { required: false })}
                  />
                </Form.Group>
                <h6>Report Type</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Check
                    bsPrefix="custom-control"
                    label={REPORT.TYPE.REPORT}
                    name="reportType"
                    type="radio"
                    id="reportTypeReport"
                    defaultChecked={
                      reportType === REPORT.TYPE.REPORT ? true : false
                    }
                    onChange={() => setReportType(REPORT.TYPE.REPORT)}
                  />
                  <Form.Check
                    bsPrefix="custom-control"
                    label={REPORT.TYPE.PAGINATED}
                    name="reportType"
                    type="radio"
                    id="reportTypePaginated"
                    defaultChecked={
                      reportType === REPORT.TYPE.PAGINATED ? true : false
                    }
                    onChange={() => setReportType(REPORT.TYPE.PAGINATED)}
                  />
                </Form.Group>
                <h6>Security</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Check
                    bsPrefix="custom-control"
                    label="None"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={!(isRowLevelSecurity || isSSASSecurity)}
                    onChange={() => {
                      setIsRowLevelSecurity(false);
                      setIsSSASSecurity(false);
                    }}
                  />
                  <Form.Check
                    bsPrefix="custom-control"
                    label="Row-Level Security"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={isRowLevelSecurity}
                    onChange={() => {
                      setIsRowLevelSecurity(true);
                      setIsSSASSecurity(false);
                    }}
                  />
                  {isRowLevelSecurity && (
                    <Form.Group className="pl-3 pt-1">
                      <ReactTags
                        allowNew
                        ariaDescribedBy="Report roles"
                        closeOnSelect
                        id="report-roles"
                        labelText="Enter new roles"
                        placeholderText="Add role(s)"
                        onAdd={onAdd}
                        onDelete={onDelete}
                        selected={selected}
                        suggestions={[]}
                      />
                      <small className="text-muted">
                        Valid role(s) that will be used as the effective
                        identity.{' '}
                      </small>
                    </Form.Group>
                  )}
                  <Form.Check
                    bsPrefix="custom-control"
                    label="SQL Server Analysis Services (SSAS) - on-premises"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={isSSASSecurity}
                    onChange={() => {
                      setIsRowLevelSecurity(false);
                      setIsSSASSecurity(true);
                    }}
                  />
                  {isSSASSecurity && (
                    <Form.Group className="pl-3 pt-1">
                      <Form.Control
                        type="text"
                        id="username"
                        placeholder="username"
                        {...register('username', { required: false })}
                      />
                      <small className="text-muted">
                        A valid username recognized by the SSAS that will be
                        used as the effective identity.{' '}
                      </small>
                    </Form.Group>
                  )}
                </Form.Group>
                <hr className="mb-0 mt-0"></hr>
                <div className="form-actions">
                  <Button variant="primary" disabled={isUpdating} type="submit">
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateReport;
