import { Link, useNavigate } from 'react-router-dom';
import { useGetOrganizationsQuery } from '../../services/portal';

const Organizations = () => {
  let navigate = useNavigate();

  const { data: organizations } = useGetOrganizationsQuery({
    forceRefetch: true
  });

  const handleNewOrganization = () => {
    navigate('/organizations/new');
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <div className="d-flex justify-content-between">
            <h1 className="page-title">
              <i className="far fa-building text-muted mr-2"></i>Organizations{' '}
            </h1>
            <div className="btn-toolbar">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNewOrganization}>
                + New
              </button>
            </div>
          </div>
        </header>

        <div className="page-section">
          <div className="card card-fluid">
            <div className="table-responsive">
              <table className="table dataTable no-footer table-striped">
                <thead>
                  <tr role="row">
                    <th>Name</th>
                    <th>Primary Contact</th>
                    <th>Secondary Contact</th>
                  </tr>
                </thead>
                <tbody>
                  {organizations?.organizations.map((item, key) => {
                    return (
                      <tr key={key} role="row">
                        <td className="align-middle text-truncate">
                          <Link to={`/organizations/${item.organization_id}`}>
                            {item.organization_name}
                          </Link>
                        </td>
                        <td className="align-middle">
                          {item.contact_persons[0].name}
                        </td>
                        <td className="align-middle">
                          {item.contact_persons[1].name}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizations;
