import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAddOrganizationMutation } from '../../services/portal';

const CreateOrganization = () => {
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(false);

  const [addOrganization, { isLoading: isUpdating }] =
    useAddOrganizationMutation();

  const handleCancel = () => {
    navigate('/organizations');
  };

  const onSubmit = (formData) => {
    setButtonSubmitDisabled(true);
    addOrganization({
      organization_name: formData.organizationName,
      contact_persons: [
        {
          name: formData.primaryName,
          email: formData.primaryEmail,
          phone: formData.primaryTelNumber
        },
        {
          name: formData.secondaryName ? formData.secondaryName : ' ',
          email: formData.secondaryEmail ? formData.secondaryEmail : ' ',
          phone: formData.secondaryTelNumber ? formData.secondaryTelNumber : ' '
        }
      ],
      comments: formData.organizationDescription
    })
      .unwrap()
      .then((payload) => {
        navigate('/organizations');
      })
      .catch((error) => console.error('rejected', error));
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="/organizations">
                  <i className="breadcrumb-icon fa fa-angle-left mr-2"></i>
                  Organizations
                </a>
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between">
            <h1 className="page-title"> New Organization </h1>
          </div>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h6>Organization </h6>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="organizationName">Name</label>
                  <div className="input-group">
                    <label
                      className="input-group-prepend"
                      htmlFor="organizationName">
                      <span className="input-group-text">
                        <span className="far fa-building"></span>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="organizationName"
                      placeholder="Organization name"
                      {...register('organizationName', {
                        required: true,
                        minLength: 3
                      })}
                    />
                  </div>
                  {errors?.organizationName &&
                    errors.organizationName.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        Organization name is required.
                      </p>
                    )}
                  {errors?.organizationName &&
                    errors.organizationName.type === 'minLength' && (
                      <p className="validate-feedback-invalid">
                        Organization name must have at least 3 characters.
                      </p>
                    )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="organizationDescription">Description</label>
                  <textarea
                    className="form-control"
                    id="organizationDescription"
                    rows="3"
                    placeholder="Organization description"
                    {...register('organizationDescription')}
                  />
                </div>
                <h6>Primary contact</h6>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="primaryName">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Full name:
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="primaryName"
                      placeholder="Full name"
                      {...register('primaryName', { required: true })}
                    />
                  </div>
                  {errors.primaryName &&
                    errors.primaryName.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        Primary contact name is required.
                      </p>
                    )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="primaryEmail">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Email:
                      </span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="primaryEmail"
                      placeholder="Email address"
                      {...register('primaryEmail', {
                        required: true,
                        pattern:
                          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
                      })}
                    />
                  </div>
                  {errors.primaryEmail && (
                    <p className="validate-feedback-invalid">
                      Valid primary contact email is required.
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="primaryTelNumber">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Phone:
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="primaryTelNumber"
                      placeholder="Phone number"
                      {...register('primaryTelNumber', { required: true })}
                    />
                  </div>
                  {errors.primaryTelNumber &&
                    errors.primaryTelNumber.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        Primary contact phone number is required.
                      </p>
                    )}
                </div>
                <h6>Secondary contact</h6>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="secondaryName">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Full name:
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="secondaryName"
                      placeholder="Full name"
                      {...register('secondaryName')}
                    />
                  </div>
                </div>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="secondaryEmail">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Email:
                      </span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="secondaryEmail"
                      placeholder="Email address"
                      {...register('secondaryEmail')}
                    />
                  </div>
                </div>
                <div className="form-group col-md-6 mb-3">
                  <div className="input-group input-group-alt">
                    <label
                      className="input-group-prepend"
                      htmlFor="secondaryTelNumber">
                      <span
                        className="input-group-text"
                        style={{ minWidth: 90 }}>
                        Phone:
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="secondaryTelNumber"
                      placeholder="Phone number"
                      {...register('secondaryTelNumber')}
                    />
                  </div>
                </div>
                <hr className="mb-0 mt-0"></hr>
                <div className="form-actions">
                  <button
                    className="btn btn-primary"
                    disabled={buttonSubmitDisabled}
                    type="submit">
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrganization;
