import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

function ImageUploader({ image, setImage, message }) {

    const [fileInputId] = useState(_uniqueId('fileinput-'));
    const [fileUploadId] = useState(_uniqueId('fileupload-'));
    const [imageId] = useState(_uniqueId('img-'));

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return (
        <div id={fileInputId} className="fileinput-dropzone d-flex align-items-center justify-content-center" style={{width: "190px", height: "120px", padding: "0px"}}>
            {image ? (
                <img id={imageId} src={image} alt="Image" style={{maxHeight: "100px", maxWidth: "100%", height: "auto"}}/>
            ) : (
                <>
                    <span>{message}</span>
                    <input id={fileUploadId} accept="image/gif,image/jpeg,image/png,image/bmp,image/svg+xml" type="file" onChange={handleFileChange} />
                </>
            )}
        </div>
    );
}

export default ImageUploader;
