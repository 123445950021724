import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const portalApi = createApi({
  reducerPath: 'portalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PORTAL_API + '/api/v1/',
    prepareHeaders: (headers, { getState }) => {
      const token = sessionStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  keepUnusedDataFor: 30,
  refetchOnMountOrArgChange: 30,
  tagTypes: [
    'Auth',
    'Organizations',
    'Users',
    'UserReports',
    'Workspaces',
    'Reports',
    'Tenants',
    'Activities',
    'Settings',
    'Support'
  ],
  endpoints: (builder) => ({
    addAuthForgotPassword: builder.mutation({
      query(data) {
        return {
          url: 'auth/forgot-password',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Auth']
    }),
    getAuthResetPassword: builder.query({
      query: (arg) => {
        const { email, token } = arg;
        return {
          url: 'auth/reset-password',
          params: { email, token }
        };
      },
      providesTags: ['Auth']
    }),
    addAuthResetPassword: builder.mutation({
      query(data) {
        return {
          url: 'auth/reset-password',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Auth']
    }),
    getOrganizations: builder.query({
      query: () => 'organizations',
      providesTags: ['Organizations']
    }),
    getOrganization: builder.query({
      query: (organizationId) => `organizations/${organizationId}`,
      providesTags: ['Organizations']
    }),
    addOrganization: builder.mutation({
      query(data) {
        return {
          url: 'organizations',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Organizations']
    }),
    updateOrganization: builder.mutation({
      query({ organization_id: id, ...patch }) {
        return {
          url: `organizations/${id}`,
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags: ['Organizations']
    }),
    getUsers: builder.query({
      query: () => 'users',
      providesTags: ['Users']
    }),
    getUser: builder.query({
      query: (userId) => `users/${userId}`,
      providesTags: ['Users']
    }),
    addUser: builder.mutation({
      query(data) {
        return {
          url: 'users',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Users']
    }),
    updateUserAccount: builder.mutation({
      query({ user_id: id, ...patch }) {
        return {
          url: `users/account/${id}`,
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags: ['Users']
    }),
    updateUserStatus: builder.mutation({
      query({ user_id: id, ...patch }) {
        return {
          url: `users/status/${id}`,
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags: ['Users']
    }),
    getUserReports: builder.query({
      query: (userId) => `userreports/reports/${userId}`,
      providesTags: ['UserReports']
    }),
    updateUserReportViewPriviledges: builder.mutation({
      query(data) {
        return {
          url: `userreports/reports/${data.user_id}`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Reports', 'UserReports']
    }),
    getWorkspaces: builder.query({
      query: () => 'workspaces',
      providesTags: ['Workspaces']
    }),
    getWorkspacesByOrganization: builder.query({
      query: (organizationId) => `workspaces/organization/${organizationId}`,
      providesTags: ['Workspaces']
    }),
    getWorkspace: builder.query({
      query: (workspaceId) => `workspaces/${workspaceId}`,
      providesTags: ['Workspaces']
    }),
    addWorkspace: builder.mutation({
      query(data) {
        return {
          url: 'workspaces',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Workspaces']
    }),
    updateWorkspace: builder.mutation({
      query({ workspace_id: id, ...patch }) {
        return {
          url: `workspaces/${id}`,
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags: ['Workspaces']
    }),
    getReports: builder.query({
      query: () => 'reports',
      providesTags: ['Reports']
    }),
    getReport: builder.query({
      query: (reportId) => `reports/${reportId}`,
      providesTags: ['Reports']
    }),
    addReport: builder.mutation({
      query(data) {
        return {
          url: 'reports',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Reports']
    }),
    updateReport: builder.mutation({
      query({ report_id: id, ...patch }) {
        return {
          url: `reports/${id}`,
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags: ['Reports']
    }),
    updateReportStatus: builder.mutation({
      query({ report_id: id, ...patch }) {
        return {
          url: `reports/status/${id}`,
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags: ['Reports']
    }),
    getTenants: builder.query({
      query: () => 'tenants',
      providesTags: ['Tenants']
    }),
    addActivityReport: builder.mutation({
      query(data) {
        return {
          url: 'activities/report',
          method: 'POST',
          body: data
        };
      }
    }),
    getAppSettings: builder.query({
      query: (app_id) => `settings/${app_id}`,
      providesTags: ['Settings']
    }),
    updateAppSettings: builder.mutation({
      query({ ...patch }) {
        return {
          url: 'settings',
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags: ['Settings']
    }),
    getSMTPSettings: builder.query({
      query: (organization_id) => `settings/admin/smtp/${organization_id}`,
      providesTags: ['Settings']
    }),
    addSMTPSettings: builder.mutation({
      query({ ...patch }) {
        return {
          url: 'settings/admin/smtp',
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags: ['Settings']
    }),
    addTicket: builder.mutation({
      query(data) {
        return {
          url: 'support/ticket',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Support']
    })
  })
});

export const {
  useAddAuthForgotPasswordMutation,
  useGetAuthResetPasswordQuery,
  useAddAuthResetPasswordMutation,
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useAddOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useAddUserMutation,
  useUpdateUserAccountMutation,
  useUpdateUserStatusMutation,
  useGetUserReportsQuery,
  useUpdateUserReportViewPriviledgesMutation,
  useGetWorkspacesQuery,
  useGetWorkspacesByOrganizationQuery,
  useGetWorkspaceQuery,
  useAddWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useGetReportsQuery,
  useGetReportQuery,
  useAddReportMutation,
  useUpdateReportMutation,
  useUpdateReportStatusMutation,
  useGetTenantsQuery,
  useAddActivityReportMutation,
  useGetAppSettingsQuery,
  useLazyGetAppSettingsQuery,
  useUpdateAppSettingsMutation,
  useGetSMTPSettingsQuery,
  useAddSMTPSettingsMutation,
  useAddTicketMutation
} = portalApi;
