import { useEffect } from 'react';
import { Alert, Container } from 'react-bootstrap';

const DismissibleAlert = ({ message, variant='info', show, setShow }) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                dismissAlert();
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [show]);

    const dismissAlert = () => {
        setShow(false);
    };

    return (
        <>
            {show && (
                <Container
                    style={{
                        position: 'fixed',
                        top: '60px',
                        right: '10px',
                        zIndex: '1000',
                        maxWidth: '380px'
                    }}>
                    <Alert variant={variant} onClose={dismissAlert} dismissible>
                        <p>{message}</p>
                    </Alert>
                </Container>
            )}
        </>
    );
};

export default DismissibleAlert;
