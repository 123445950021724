import React from 'react';
import { Link } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import {
  useGetReportsQuery,
  useGetWorkspacesQuery
} from '../../services/portal';

export default function MyReports() {
  const { data: workspaces } = useGetWorkspacesQuery({ forceRefetch: true });
  const { data: reports } = useGetReportsQuery({ forceRefetch: true });
  var workspacePosition = -1;

  return (
    <div className="card">
      <div className="card-header h5">
        <i className="fas fa-chart-pie text-muted mr-2"></i> My Reports
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead></thead>
          <tbody>
            {workspaces?.workspaces
              .filter((workspace) => workspace.active === true)
              .map((item, key) => {
                if (
                  reports?.reports.filter(
                    (report) =>
                      report.workspace.workspace_id === item.workspace_id &&
                      report.active === true
                  ).length > 0
                ) {
                  return (
                    <React.Fragment key={key}>
                      <tr>
                        <td
                          className="align-middle px-2"
                          style={{ width: '1.5rem' }}>
                          <button
                            type="button"
                            className="btn btn-sm btn-icon btn-light collased"
                            data-toggle="collapse"
                            data-target={'#details-' + item.workspace_id}>
                            <span className="collapse-indicator">
                              <i className="fa fa-angle-right"></i>
                            </span>
                          </button>
                        </td>
                        <td
                          className="align-middle"
                          style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          {item.workspace_name}
                        </td>
                      </tr>
                      <tr
                        className={`row-details bg-light collapse  ${
                          ++workspacePosition === 0 ? 'show' : ''
                        }`}
                        id={'details-' + item.workspace_id}>
                        <td colSpan="3">
                          <div className="row">
                            <div className="col-md-auto text-center">
                              <div className="tile tile-xl tile-circle bg-primary mb-2">
                                {item.workspace_shortname}
                              </div>
                              <h3 className="card-title mb-4">
                                {item.organization.organization_name}
                              </h3>
                            </div>
                            <div className="col-md-11">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        fontSize: '12px',
                                        width: '60%'
                                      }}>
                                      {' '}
                                      Report{' '}
                                    </th>
                                    <th
                                      style={{
                                        fontSize: '12px',
                                        width: '10%'
                                      }}>
                                      {' '}
                                      Type{' '}
                                    </th>
                                    <th
                                      style={{
                                        fontSize: '12px',
                                        width: '10%'
                                      }}>
                                      {' '}
                                      Created{' '}
                                    </th>
                                    <th style={{ fontSize: '12px' }}>
                                      {' '}
                                      Last Viewed{' '}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reports?.reports
                                    .filter(
                                      (report) =>
                                        report.workspace.workspace_id ===
                                          item.workspace_id &&
                                        report.active === true
                                    )
                                    .map((filteredWorkspace) => (
                                      <tr
                                        key={
                                          'report-' +
                                          filteredWorkspace.report_id
                                        }>
                                        <td className="align-middle">
                                          <Link
                                            // eslint-disable-next-line max-len
                                            to={`/report/${filteredWorkspace.report_type}/${filteredWorkspace.workspace.workspace_id}/${filteredWorkspace.workspace.workspace_code}/${filteredWorkspace.report_id}/${filteredWorkspace.report_code}/${filteredWorkspace.report_name}`}>
                                            {filteredWorkspace.report_name}
                                          </Link>
                                        </td>
                                        <td className="align-middle">
                                          <span className="badge badge-subtle badge-light">
                                            {filteredWorkspace.report_type}
                                          </span>
                                        </td>
                                        <td className="align-middle">
                                          {filteredWorkspace.created_at && (
                                            <ReactTimeAgo
                                              date={Date.parse(
                                                filteredWorkspace.created_at
                                              )}
                                              locale="en-US"
                                            />
                                          )}
                                        </td>
                                        <td className="align-middle">
                                          {filteredWorkspace.viewed_at && (
                                            <ReactTimeAgo
                                              date={Date.parse(
                                                filteredWorkspace.viewed_at
                                              )}
                                              locale="en-US"
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
