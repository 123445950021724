import { createBrowserHistory } from 'history';
import Parser from 'html-react-parser';
import { useContext, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import avatar from '../../assets/images/avatars/person-circle.svg';
import ThemeContext from '../../contexts/ThemeContext';
import ThemeSetter from '../ThemeSetter/ThemeSetter';

const history = createBrowserHistory({ forceRefresh: true });

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

const NavigationBar = () => {
  const { logo, logoText } = useContext(ThemeContext);
  const [user, setUser] = useState(getSessionStorageOrDefault('user', null));

  const handleLogout = async (e) => {
    e.preventDefault();
    sessionStorage.clear();
    history.push('/login');
  };

  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      expand="sm"
      bg="primary"
      variant="dark">
      <Navbar.Brand href="/">
        {logo && logo.length > 0 ? (
          <img
            src={logo}
            className="align-top d-inline-block"
            alt="Logo"
            height="35"
            width="180"
            align="bottom"></img>
        ) : (
          Parser(logoText || 'Business Intelligence Portal')
        )}
      </Navbar.Brand>
      <Nav className="mr-auto"></Nav>
      <Nav className="ms-auto">
        <Nav.Link>
          <ThemeSetter size={20} />
        </Nav.Link>
        <div className="dropdown d-none d-sm-flex">
          <button
            type="button"
            className="btn-account"
            data-toggle="dropdown"
            variant="account"
            block="true">
            <span className="user-avatar">
              <img src={avatar} alt="Avatar" />
            </span>
            <span className="account-summary pr-lg-4 d-lg-block">
              <span className="account-name">{user.family_name}</span>
              <span className="account-description">
                {user.company ? user.company : user.organization}
              </span>
            </span>
          </button>
          <div className="dropdown-menu">
            <div className="dropdown-arrow d-lg-none"></div>
            <div className="dropdown-arrow ml-3 d-none d-lg-block"></div>
            {(user.role === 'admin' || user.role === 'manager') && (
              <h6 className="dropdown-header"> Administration </h6>
            )}
            {user.role === 'admin' && (
              <Link to="/organizations" className="dropdown-item">
                <span className="dropdown-icon far fa-building"></span>{' '}
                Organizations
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/users" className="dropdown-item">
                <span className="dropdown-icon fas fa-users"></span> Users
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/workspaces" className="dropdown-item">
                <span className="dropdown-icon far fa-object-group"></span>{' '}
                Workspaces
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/reports" className="dropdown-item">
                <span className="dropdown-icon fas fa-chart-pie"></span> Reports
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <div className="dropdown-divider"></div>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <h6 className="dropdown-header"> Preferences </h6>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/preferences/branding" className="dropdown-item">
                <span className="dropdown-icon fas fa-sliders-h"></span>{' '}
                Branding
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/preference/smtp" className="dropdown-item">
                <span className="dropdown-icon fas fa-envelope"></span> SMTP
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <div className="dropdown-divider"></div>
            )}
            <Link to="/" className="dropdown-item">
              <span className="dropdown-icon fas fa-chart-pie"></span> My
              Reports
            </Link>
            <Link to="/" className="dropdown-item" onClick={handleLogout}>
              <span className="dropdown-icon fas fa-sign-out-alt"></span> Logout
            </Link>
            <div className="dropdown-divider"></div>
            <Link to="/support/ticket" className="dropdown-item">
              <span></span> Help Center
            </Link>
          </div>
        </div>
      </Nav>
    </Navbar>
  );
};

export default NavigationBar;
