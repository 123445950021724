import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/illustration/img-6.svg';

const Submitted = () => {
    return (
        <div id="notfound-state" className="empty-state">
            <div className="empty-state-container">
                <div className="state-figure">
                    <img className="img-fluid" src={logo} alt="" style={{ maxWidth: '300px' }} />
                </div>
                <h3 className="state-header">Thank you</h3>
                <p className="state-description lead text-muted">Your submission has been received.</p>
                <div className="state-action">
                    <Link to="/" className="btn btn-lg btn-light">
                        <span className="fa fa-home"></span> Take me home
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Submitted;
