import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Spinner
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactTags } from 'react-tag-autocomplete';
import ParameterValueCapture from './ParameterValueCapture';
import { useGetUsersQuery } from '../../../services/portal';
import { useAddReportSubscriptionMutation } from '../../../services/processor.service';
import DismissibleAlert from '../../AlertDismissible/DismissibleAlert';

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

const CreateSubscription = () => {
  let navigate = useNavigate();
  let { reportType, reportId, reportCode, reportName } = useParams();

  const repeats = ['Daily'];
  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = ['00', '15', '30', '45'];
  const periods = ['AM', 'PM'];

  const [user, setUser] = useState(getSessionStorageOrDefault('user', null));
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedRepeat, setSelectedRepeat] = useState('Daily');
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState('00');
  const [selectedPeriod, setSelectedPeriod] = useState('AM');
  const [cronExpression, setCronExpression] = useState('');
  const [description, setDescription] = useState('');

  const [recipientSuggestions, setRecipientSuggestions] = useState([]);
  const [selectedRecipients, setRecipients] = useState([]);
  const [parameterValues, setParameterValues] = useState([]);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');

  const { data: users } = useGetUsersQuery({ forceRefetch: true });

  const [addReportSubscription, { isLoading }] =
    useAddReportSubscriptionMutation();

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (users) {
      const suggestions = users.users
        .filter((usr) => usr.organization.organization_id === user.oid)
        .map((user) => ({
          value: user.email,
          label: user.email
        }));
      setRecipientSuggestions(suggestions);
    }
  }, [user.oid, users]);

  useEffect(() => {
    if (selectedRecipients.length === 0) {
      setError('recipients', {
        message: 'Specify at least one email address',
        type: 'manual'
      });
    } else {
      clearErrors('recipients');
    }
  }, [selectedRecipients]);

  useEffect(() => {
    const now = new Date();
    const currentHour = (now.getHours() + 1) % 12 || 12;
    const currentPeriod = now.getHours() < 12 ? 'AM' : 'PM';

    setSelectedHour(currentHour);
    setSelectedPeriod(currentPeriod);
  }, []);

  useEffect(() => {
    // Convert selected options to cron expression
    const hour24Format =
      selectedPeriod === 'AM'
        ? selectedHour % 12
        : selectedHour + 12 === 24
        ? 12
        : selectedHour + 12;
    const cronString = `${selectedMinute} ${hour24Format} * * ${getDaysOfWeek(
      selectedRepeat
    )}`;

    setCronExpression(cronString);

    // Generate description
    const formattedStartDate = format(selectedStartDate, 'EEEE, d MMMM yyyy');
    const description = `Emails will be sent ${selectedRepeat.toLowerCase()} at ${formatTime(
      selectedHour,
      selectedMinute,
      selectedPeriod
    )} South Africa Standard Time starting ${formattedStartDate}.`;
    setDescription(description);
  }, [
    selectedHour,
    selectedMinute,
    selectedPeriod,
    selectedRepeat,
    selectedStartDate
  ]);

  // Function to map repeat type to days of the week
  const getDaysOfWeek = (repeatType) => {
    if (repeatType === 'Daily') {
      return '*';
    } else if (repeatType === 'Weekly') {
      return '0-6';
    }
    return '*';
  };

  // Function to format time with AM/PM
  const formatTime = (hour, minute, period) => {
    const formattedHour = period === 'AM' ? hour : hour % 12;
    const formattedPeriod = period;
    return `${formattedHour}:${minute
      .toString()
      .padStart(2, '0')} ${formattedPeriod}`;
  };

  // Callback function to receive the pairs from ParameterValueCapture
  const handleParameterValuesChange = (newPairs) => {
    setParameterValues(newPairs);
  };

  const handleCancel = () => {
    navigate(
      `/notifications/subscriptions/reports/${reportId}/${reportCode}/${reportName}`
    );
  };

  const onRecipientsAdd = useCallback(
    (newTag) => {
      setRecipients([...selectedRecipients, newTag]);
    },
    [selectedRecipients]
  );

  const onRecipientsDelete = useCallback(
    (tagIndex) => {
      setRecipients(selectedRecipients.filter((_, i) => i !== tagIndex));
    },
    [selectedRecipients]
  );

  const onSubmit = (formData) => {
    const data = {
      subscriptionName: formData.subscriptionName,
      organizationId: Number(user.oid),
      reportId: Number(reportId),
      name: formData.subscriptionName,
      recipients: selectedRecipients.map((recipient) => recipient.value),
      startDate: selectedStartDate,
      endDate: selectedEndDate || null,
      schedule: cronExpression
    };

    if (reportType === 'Paginated Report') {
      data.paginatedParameterValues = parameterValues;
    }

    addReportSubscription(data)
      .unwrap()
      .then((payload) => {
        setAlertMessage('Successfully added subscription');
        setAlertVariant('success');
        setAlertVisible(true);
        setTimeout(() => {
          navigate(
            // eslint-disable-next-line max-len
            `/notifications/subscriptions/reports/${reportType}/${reportId}/${reportCode}/${reportName}`
          );
        }, 2000);
      })
      .catch((error) => {
        console.error('rejected', error);
        setAlertMessage(error.data.message);
        setAlertVariant('danger');
        setAlertVisible(true);
      });
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link}>
              <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkProps={{ to: '/reports' }} linkAs={Link}>
              Reports
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkProps={{
                // eslint-disable-next-line max-len
                to: `/notifications/subscriptions/reports/${reportType}/${reportId}/${reportCode}/${reportName}`
              }}
              linkAs={Link}>
              Subscriptions
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="page-title"> Subscriptions </h1>
          <p className="text-muted">
            Keep track of your data by subscribing to this report.
          </p>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h6>Subscription details</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Subscription name</Form.Label>
                  <Form.Control
                    type="text"
                    id="subscriptionName"
                    defaultValue={reportName}
                    placeholder="Enter a name"
                    {...register('subscriptionName', {
                      maxLength: 200,
                      minLength: 3,
                      required: true
                    })}
                  />
                  {errors?.name && errors.name.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Enter a subscription name.
                    </p>
                  )}
                  {errors?.name && errors.name.type === 'minLength' && (
                    <p className="validate-feedback-invalid">
                      Subscription name must be at least 3 characters.
                    </p>
                  )}
                  {errors?.name && errors.name.type === 'maxLength' && (
                    <p className="validate-feedback-invalid">
                      Subscription name must not be longer than 200 characters.
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Recipients</Form.Label>
                  <Controller
                    name="suggestions"
                    control={control}
                    render={() => (
                      <ReactTags
                        allowNew={false}
                        ariaDescribedBy="Enter email address"
                        closeOnSelect
                        id="recipients"
                        labelText="Enter email address"
                        placeholderText="Enter email address"
                        onAdd={onRecipientsAdd}
                        onDelete={onRecipientsDelete}
                        selected={selectedRecipients}
                        suggestions={recipientSuggestions}
                      />
                    )}
                  />
                  {errors?.recipients && (
                    <p className="validate-feedback-invalid">
                      {errors.recipients.message}
                    </p>
                  )}
                </Form.Group>

                {reportType === 'Paginated Report' && (
                  <React.Fragment>
                    <h6>Report parameters</h6>
                    <Form.Group className="form-group col-md-6 mb-3">
                      <ParameterValueCapture
                        onPairsChange={handleParameterValuesChange}
                      />
                    </Form.Group>
                  </React.Fragment>
                )}
                <h6>Scheduled date and time</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Row>
                    <Col className="col-md-6 mb-3">
                      <Form.Label>Start date</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <DatePicker
                          selected={selectedStartDate}
                          onChange={(date) => setSelectedStartDate(date)}
                          minDate={new Date()}
                          className="form-control"
                        />
                      </InputGroup>
                    </Col>
                    <Col className="col-md-6 mb-3">
                      <Form.Label>End date</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <DatePicker
                          selected={selectedEndDate}
                          onChange={(date) => setSelectedEndDate(date)}
                          minDate={new Date()}
                          className="form-control"
                        />
                      </InputGroup>
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Repeat</Form.Label>
                  <Form.Control
                    as="select"
                    className="custom-select"
                    custom
                    disabled>
                    {repeats.map((repeat) => (
                      <option key={repeat} value={repeat}>
                        {repeat}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Scheduled time</Form.Label>
                  <Form.Row>
                    <Col className="col-md-1 mb-3">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          {selectedHour}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            minWidth: '3rem'
                          }}>
                          {hours.map((hour) => (
                            <Dropdown.Item
                              key={hour}
                              onClick={() => setSelectedHour(hour)}>
                              {hour}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col className="col-md-1 mb-3">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          {selectedMinute}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            minWidth: '3rem'
                          }}>
                          {minutes.map((minute) => (
                            <Dropdown.Item
                              key={minute.toString().padStart(2, '0')}
                              onClick={() => setSelectedMinute(minute)}>
                              {minute.toString().padStart(2, '0')}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col className="col-md-1 mb-3">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          {selectedPeriod}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            minWidth: '3rem'
                          }}>
                          {periods.map((period) => (
                            <Dropdown.Item
                              key={period}
                              onClick={() => setSelectedPeriod(period)}>
                              {period}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Timezone</Form.Label>
                  <Form.Control
                    as="select"
                    className="custom-select"
                    custom
                    disabled>
                    <option value="Daily">(UTC+02:00) Harare, Pretoria</option>
                  </Form.Control>
                </Form.Group>
                <div>{description != null && <p>{description}</p>}</div>

                <hr className="mb-0 mt-0"></hr>
                <div className="form-actions">
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: 5 }}
                        />
                        Submitting...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </Form>
              <DismissibleAlert
                message={alertMessage}
                variant={alertVariant}
                show={alertVisible}
                setShow={setAlertVisible}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSubscription;
