import { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  useGetUserQuery,
  useUpdateUserAccountMutation
} from '../../services/portal';
import DismissibleAlert from '../AlertDismissible/DismissibleAlert';

const EditUserAccount = ({ userId }) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const { data: user } = useGetUserQuery(userId, { forceRefetch: true });
  const [updateUserAccount, { isLoading }] = useUpdateUserAccountMutation();

  useEffect(() => {
    if (user) {
      setValue('firstName', user.first_name);
      setValue('lastName', user.last_name);
      setValue('email', user.email);
      setValue('phone', user.phone);
      setValue('company', user.organization.company);
      setValue('department', user.organization.department);
    }
  }, [user]);

  const onSubmit = (formData) => {
    updateUserAccount({
      user_id: userId,
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone: formData.phone,
      company: formData.company,
      department: formData.department
    })
      .unwrap()
      .then((payload) => {
        setAlertMessage(payload.message);
        setAlertVisible(true);
      })
      .catch((error) => {
        setAlertVariant('danger');
        setAlertMessage(error.data.message);
        setAlertVisible(true);
      });
  };

  return (
    <>
      <h6>Account</h6>
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="form-group col-md-6 mb-3">
          <Form.Label>First name</Form.Label>
          <Form.Control
            id="firstName"
            placeholder="First name"
            {...register('firstName', { required: true })}
          />
          {errors?.firstName && errors.firstName.type === 'required' && (
            <p className="validate-feedback-invalid">First name is required</p>
          )}
        </Form.Group>
        <Form.Group className="form-group col-md-6 mb-3">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            id="lastName"
            placeholder="Last name"
            {...register('lastName', { required: true })}
          />
          {errors?.lastName && errors.lastName.type === 'required' && (
            <p className="validate-feedback-invalid">Last name is required</p>
          )}
        </Form.Group>
        <Form.Group className="form-group col-md-6 mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            id="email"
            placeholder="Email address"
            disabled={true}
            {...register('email')}
          />
          {errors?.email && (
            <p className="validate-feedback-invalid">Valid email is required</p>
          )}
        </Form.Group>
        <Form.Group className="form-group col-md-6 mb-3">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            id="phone"
            placeholder="Phone number"
            required=""
            {...register('phone', { required: false })}
          />
        </Form.Group>
        <Form.Group className="form-group col-md-6 mb-3">
          <Form.Label>Company</Form.Label>
          <Form.Control
            id="company"
            placeholder="Company Name"
            required=""
            {...register('company', { required: false })}
          />
        </Form.Group>
        <Form.Group className="form-group col-md-6 mb-3">
          <Form.Label>Department</Form.Label>
          <Form.Control
            id="department"
            placeholder="Department"
            required=""
            {...register('department', { required: false })}
          />
        </Form.Group>
        <hr className="mb-0 mt-0"></hr>
        <div className="form-actions">
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: 5 }}
                />
                Submitting...
              </>
            ) : (
              'Update account settings'
            )}
          </Button>
        </div>
      </Form>
      <DismissibleAlert
        message={alertMessage}
        variant={alertVariant}
        show={alertVisible}
        setShow={setAlertVisible}
      />
    </>
  );
};

export default EditUserAccount;
