import { useState } from 'react';
import MyReports from '../components/MyReports/MyReports';

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

const Home = () => {
  const [user, setUser] = useState(getSessionStorageOrDefault('user', null));

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <div className="d-flex flex-column flex-md-row">
            <p className="lead">
              <span className="font-weight-bold">Good day, {user.name}.</span>
              <span className="d-block text-muted">
                Find and share actionable insights to make data-driven
                decisions.
              </span>
            </p>
          </div>
        </header>

        <div className="page-section">
          <MyReports />
        </div>
      </div>
    </div>
  );
};

export default Home;
