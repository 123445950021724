import { useState } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';

import {
  useGetUsersQuery,
  useUpdateUserStatusMutation
} from '../../services/portal';

const Users = () => {
  let navigate = useNavigate();

  const [selectedUserOrganization, setSelectedUserOraganization] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedUserFullName, setSelectedUserFullName] = useState('');
  const [selectedUserStatus, setSelectedUserStatus] = useState(false);
  const [userBlockModelShow, setUserBlockModalShow] = useState(false);

  const { data: users } = useGetUsersQuery({ forceRefetch: true });
  const [updateUserStatus] = useUpdateUserStatusMutation();

  const handleUserBlockModalShow = () => {
    setUserBlockModalShow(true);
  };

  const handleUserBlockModalCancel = () => {
    setUserBlockModalShow(false);
  };

  const handleUserNew = () => {
    navigate('/users/new');
  };

  const handleSaveUserStatus = () => {
    updateUserStatus({
      user_id: selectedUserId,
      status: selectedUserStatus
    })
      .unwrap()
      .then((payload) => {
        console.debug('User sucessfully updated');
      })
      .catch((error) => console.error('rejected', error));

    setUserBlockModalShow(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <div className="d-flex justify-content-between">
            <h1 className="page-title">
              <i className="fas fa-users text-muted mr-2"></i>Users
            </h1>
            <div className="btn-toolbar">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUserNew}>
                + New
              </button>
            </div>
          </div>
        </header>

        <div className="page-section">
          <Card>
            <Tab.Container
              id="user-tabs"
              defaultActiveKey="active"
              transition={false}>
              <Card.Header>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="active">Active</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="blocked">Blocked</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="active">
                    <div className="table-responsive">
                      <table className="table dataTable no-footer table-striped">
                        <thead>
                          <tr role="row">
                            <th>Organization</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Department</th>
                            <th>Role</th>
                            <th style={{ minWidth: '100px', width: '100px' }}>
                              {' '}
                              &nbsp;{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.users
                            .filter((user) => user.active === true)
                            .map((item, key) => {
                              return (
                                <tr key={key} role="row">
                                  <td className="align-middle">
                                    {item.organization.organization_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.first_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.last_name}
                                  </td>
                                  <td className="align-middle">{item.phone}</td>
                                  <td className="align-middle">{item.email}</td>
                                  <td className="align-middle">
                                    {item.organization.company}
                                  </td>
                                  <td className="align-middle">
                                    {item.organization.department}
                                  </td>
                                  <td className="align-middle">
                                    <span className="badge badge-subtle badge-light">
                                      {item.role}
                                    </span>
                                  </td>
                                  <td className="align-middle text-right">
                                    <Link
                                      to={`/users/${item.user_id}`}
                                      className="btn btn-sm btn-icon btn-secondary"
                                      style={{ marginRight: 5 }}>
                                      <i className="fa fa-pencil-alt"></i>{' '}
                                      <span className="sr-only">Edit</span>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-icon btn-secondary"
                                      onClick={() => {
                                        setSelectedUserId(item.user_id);
                                        setSelectedUserOraganization(
                                          item.organization.organization_name
                                        );
                                        setSelectedUserFullName(
                                          item.first_name + ' ' + item.last_name
                                        );
                                        setSelectedUserStatus(false);
                                        handleUserBlockModalShow();
                                      }}>
                                      <i className="far fa-trash-alt"></i>{' '}
                                      <span className="sr-only">Remove</span>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="blocked">
                    <div className="table-responsive">
                      <table className="table dataTable no-footer table-striped">
                        <thead>
                          <tr role="row">
                            <th>Organization</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Department</th>
                            <th>Role</th>
                            <th style={{ minWidth: '100px', width: '100px' }}>
                              {' '}
                              &nbsp;{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.users
                            .filter((user) => user.active === false)
                            .map((item, key) => {
                              return (
                                <tr key={key} role="row">
                                  <td className="align-middle">
                                    {item.organization.organization_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.first_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.last_name}
                                  </td>
                                  <td className="align-middle">{item.phone}</td>
                                  <td className="align-middle">{item.email}</td>
                                  <td className="align-middle">
                                    {item.organization.company}
                                  </td>
                                  <td className="align-middle">
                                    {item.organization.department}
                                  </td>
                                  <td className="align-middle">
                                    <span className="badge badge-subtle badge-light">
                                      {item.role}
                                    </span>
                                  </td>
                                  <td className="align-middle text-right">
                                    <Link
                                      to={`/users/${item.user_id}`}
                                      className="btn btn-sm btn-icon btn-secondary"
                                      style={{ marginRight: 5 }}>
                                      <i className="fa fa-pencil-alt"></i>{' '}
                                      <span className="sr-only">Edit</span>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-icon btn-secondary"
                                      onClick={() => {
                                        setSelectedUserId(item.user_id);
                                        setSelectedUserOraganization(
                                          item.organization.organization_name
                                        );
                                        setSelectedUserFullName(
                                          item.first_name + ' ' + item.last_name
                                        );
                                        setSelectedUserStatus(true);
                                        handleUserBlockModalShow();
                                      }}>
                                      <i className="fas fa-trash-restore-alt"></i>{' '}
                                      <span className="sr-only">Remove</span>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </div>
      </div>

      <Modal
        show={userBlockModelShow}
        onHide={handleUserBlockModalCancel}
        animation={false}
        centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <h6>Block user</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to {selectedUserStatus ? 'unblock' : 'block'}{' '}
            <strong>{selectedUserFullName}</strong> from the{' '}
            <strong>{selectedUserOrganization}</strong> organization?
          </p>
          <hr className="mb-0 mt-0"></hr>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUserBlockModalCancel}>
            Cancel
          </Button>
          <Button variant="warning" onClick={handleSaveUserStatus}>
            {selectedUserStatus ? 'Unblock user' : 'Block user'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Users;
