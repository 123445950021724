import cronstrue from 'cronstrue';
import { useState } from 'react';
import { Breadcrumb, Button, Card, Modal, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import {
  useDeleteReportSubscriptionMutation,
  useGetReportSubscriptionQuery
} from '../../../services/processor.service';

const Subscriptions = () => {
  let navigate = useNavigate();
  let { reportType, reportId, reportCode, reportName } = useParams();
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const [subscriptionModelShow, setSubscriptionModalShow] = useState(false);

  const { data: subscription } = useGetReportSubscriptionQuery(reportCode, {
    forceRefetch: true
  });
  const [deleteReportSubscription] = useDeleteReportSubscriptionMutation();

  const handleSubscriptionModalShow = () => {
    setSubscriptionModalShow(true);
  };

  const handleSubscriptionModalCancel = () => {
    setSubscriptionModalShow(false);
  };

  const handleNewSubscription = () => {
    navigate(
      // eslint-disable-next-line max-len
      `/notifications/subscriptions/reports/${reportType}/${reportId}/${reportCode}/${reportName}/new`
    );
  };

  const handleSubscriptionDelete = () => {
    deleteReportSubscription(selectedSubscriptionId)
      .unwrap()
      .then((payload) => {
        console.debug('Successfully deleted subscription', payload);
      })
      .catch((error) => console.error('rejected', error));

    setSubscriptionModalShow(false);
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link}>
              <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkProps={{ to: '/reports' }} linkAs={Link}>
              Reports
            </Breadcrumb.Item>
            <Breadcrumb.Item className="active">Subscriptions</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between">
            <h1 className="page-title"> Subscriptions </h1>
            <div className="btn-toolbar">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNewSubscription}>
                Create a subscription
              </button>
            </div>
          </div>
          <p className="text-muted">
            Keep track of your data by subscribing to this report.
          </p>
        </header>
        <div className="page-section">
          <Card>
            <Tab.Container
              id="report-tabs"
              defaultActiveKey="subscriptions"
              transition={false}>
              <Card.Header>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="subscriptions">
                      Power BI subscriptions
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="subscriptions">
                    <div className="table-responsive">
                      <table className="table dataTable no-footer table-striped">
                        <thead>
                          <tr role="row">
                            <th>Subscription name</th>
                            <th>Content name</th>
                            <th>Type</th>
                            <th>Owner</th>
                            <th>Created</th>
                            <th>Schedule</th>
                            <th style={{ minWidth: '130px', width: '100px' }}>
                              {' '}
                              &nbsp;{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscription?.map((item, key) => {
                            return (
                              <tr key={key} role="row">
                                <td className="align-middle">
                                  {item.subscriptionName}
                                </td>
                                <td className="align-middle text-truncate">
                                  {item.contentName}
                                </td>
                                <td className="align-middle">{item.type}</td>
                                <td className="align-middle">{item.owner}</td>
                                <td className="align-middle">
                                  <ReactTimeAgo
                                    date={Date.parse(item.createdAt)}
                                    locale="en-US"
                                  />
                                </td>
                                <td className="align-middle">
                                  {cronstrue.toString(item.schedule, {
                                    verbose: true
                                  })}
                                </td>
                                <td className="align-middle text-right">
                                  {/* <Link
                                    to={`/notifications/subscriptions/reports/${reportId}/${reportCode}/${reportName}/edit`}
                                    className="btn btn-sm btn-icon btn-secondary"
                                    style={{ marginRight: 5 }}>
                                    <i className="fa fa-pencil-alt"></i>{' '}
                                    <span className="sr-only">Edit</span>
                                  </Link> */}
                                  <Link
                                    to="#"
                                    className="btn btn-sm btn-icon btn-secondary"
                                    onClick={() => {
                                      setSelectedSubscriptionId(item.jobId);
                                      handleSubscriptionModalShow();
                                    }}>
                                    <i className="far fa-trash-alt"></i>{' '}
                                    <span className="sr-only">
                                      Delete subscription
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </div>
      </div>

      <Modal
        show={subscriptionModelShow}
        onHide={handleSubscriptionModalCancel}
        animation={false}
        centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <h6>Delete subscription</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This will remove the subscription permanently and no further emails
            will be sent.
          </p>
          <hr className="mb-0 mt-0"></hr>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSubscriptionModalCancel}>
            Cancel
          </Button>
          <Button variant="warning" onClick={handleSubscriptionDelete}>
            Delete subscription
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Subscriptions;
