import { useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import EditUserAccount from './EditUserAccount';
import {
  useGetUserQuery,
  useGetUserReportsQuery,
  useUpdateUserReportViewPriviledgesMutation
} from '../../services/portal';

const EditUser = () => {
  let { userId: userId } = useParams();

  const [checkedState, setCheckedState] = useState(new Array(100).fill(false));

  const { data: user } = useGetUserQuery(userId, { forceRefetch: true });
  const { data: userReports } = useGetUserReportsQuery(userId, {
    forceRefetch: true
  });
  const [updateUserReportViewPriviledges] =
    useUpdateUserReportViewPriviledgesMutation();

  useEffect(() => {
    if (userReports) {
      const updatedCheckedState = userReports.user_reports.map(
        (item, index) => {
          return Boolean(item.has_view_priviledges);
        }
      );
      setCheckedState(updatedCheckedState);
    }
  }, [userReports]);

  const handleOnChange = (position, reportId) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

    // Submit user report priviledges
    updateUserReportViewPriviledges({
      user_id: userId,
      report_id: reportId,
      has_view_priviledges: updatedCheckedState[position]
    })
      .unwrap()
      .then((payload) => {})
      .catch((error) => console.error('rejected', error));
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="active"
              linkProps={{ to: '/users' }}
              linkAs={Link}>
              <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> Users
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex flex-column">
            <h1 className="page-title">
              <i className="fas fa-user text-muted mr-2"></i>
              {user && user.first_name + ' ' + user.last_name}
            </h1>
          </div>
        </header>
        <Tab.Container
          id="user-tabs"
          defaultActiveKey="permissions"
          className="nav nav-tabs flex-column border-0"
          transition={false}>
          <Row>
            <Col lg={4}>
              <Card className="card card-fluid">
                <Card.Body>
                  <Nav
                    variant="tabs"
                    className="nav nav-tabs flex-column border-0 ml-n3">
                    <Nav.Item>
                      <Nav.Link eventKey="permissions">Permissions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="account">Account</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mb-2">
                <Card.Body>
                  <Tab.Content>
                    <Tab.Pane eventKey="account">
                      <EditUserAccount userId={userId} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="permissions">
                      <h6>Report permissions</h6>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Report</th>
                              <th>Workspace</th>
                              <th>Type</th>
                              <th>Organization</th>
                              <th style={{ minWidth: '100px', width: '100px' }}>
                                View
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userReports?.user_reports.map((item, key) => {
                              return (
                                <tr key={key}>
                                  <td className="align-middle">
                                    {item.report_name}
                                  </td>
                                  <td className="align-middle">
                                    {item.workspace.workspace_name}
                                  </td>
                                  <td className="align-middle">
                                    <span className="badge badge-subtle badge-light">
                                      Report
                                    </span>
                                  </td>
                                  <td className="align-middle">
                                    {item.organization.organization_name}
                                  </td>
                                  <td className="align-middle">
                                    <div className="custom-control custom-control-nolabel custom-checkbox">
                                      <Form.Check
                                        type="checkbox"
                                        custom
                                        bsPrefix="custom-control-input"
                                        checked={checkedState[key]}
                                        onChange={() =>
                                          handleOnChange(key, item.report_id)
                                        }
                                        value={item.report_id}
                                        id={item.report_id}
                                        name={item.report_id}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default EditUser;
