import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { Button, Form, Modal, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ParameterValueCapture = ({ onPairsChange }) => {
  const [pairs, setPairs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const navLinkRef = useRef(null);

  const handleAddPair = () => {
    if (name && value) {
      const newPair = { name, value };
      setPairs([...pairs, newPair]);
      setShowModal(false);
      setName('');
      setValue('');
      onPairsChange([...pairs, newPair]);
    }
  };

  const handleRemovePair = (index) => {
    const updatedPairs = pairs.filter((_, i) => i !== index);
    setPairs(updatedPairs);
    onPairsChange(updatedPairs);
  };

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr role="row">
              <th>Parameter</th>
              <th>Value/Field</th>
              <th style={{ minWidth: '130px', width: '100px' }}> &nbsp; </th>
            </tr>
          </thead>
          <tbody>
            {pairs.map((pair, index) => {
              return (
                <tr key={index} role="row">
                  <td className="align-middle">{pair.name}</td>
                  <td className="align-middle">{pair.value}</td>
                  <td className="align-middle text-right">
                    <Link
                      className="btn btn-sm btn-icon btn-secondary"
                      onClick={() => handleRemovePair(index)}>
                      <i className="far fa-trash-alt"></i>{' '}
                      <span className="sr-only">Delete parameter</span>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <Nav>
            <Nav.Link
              ref={navLinkRef}
              href="#"
              onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlusCircle} /> Add parameter
            </Nav.Link>
          </Nav>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Add report parameter values</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter value"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleAddPair}
            disabled={!name || !value}>
            Add Parameter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ParameterValueCapture;
